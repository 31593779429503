import {
  ADMIN_SEARCH_RESULTS_RECEIVED
  } from './types';

export const adminSearchAction = (searchQuery) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/admin/search';

    const postData = {
      "searchQuery": searchQuery
    };

    fetch(url, {
      method: "post",
      body: JSON.stringify(postData),
      headers: {
        'Authorization': authToken
      }
    }).then(response => {

      if (response.status === 200) {

        response.json().then(data => {
          dispatch(
            {
              type: ADMIN_SEARCH_RESULTS_RECEIVED,
              payload: {
                data: data
              }
            }
          );
          resolve(data);
        })

      } else {
        reject(); // 404 or some other server error
      }

    }).catch( error => {
      reject(); // network error
    });

  });
