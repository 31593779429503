import React, { Component } from 'react';
import LoggedOutHeader from '../../ui/LoggedOutHeader';
import { connect } from 'react-redux';
import { gotoStep4, gotoStep5 } from '../../../actions/forgotPasswordActions'
import AppSettings from '../../../config/appsettings';

class ForgotPasswordStep4 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      code: '',
      errorMessage: null
    }

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  onSubmit(e) {

    e.preventDefault();

    // clear any existing error messages
    this.setState({errorMessage: null})

    // Return an error message if passwords do not match
    if (this.state.password1 !== this.state.password2) {
      this.setState({errorMessage: "Passwords do not match"});
      return;
    }

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user/' + this.props.username.trim().toLowerCase() + '/resetPassword';
    const patchData = {
      password: this.state.password1,
      token: this.props.token
    };
    fetch(url, {
      method: "PATCH",
      body: JSON.stringify(patchData)
    })
    .then(response => {

      if (response.status === 204) {
        this.props.dispatch(gotoStep5());
      } else {

        /* Failed to change password, so report the error message */
        response.json().then(responseObject => {
            this.setState({errorMessage: responseObject.detail});

            /* If a new token was returned, set it */
            if (responseObject.hasOwnProperty('data')) {
                if (responseObject.data.hasOwnProperty('newToken')) {
                    this.props.dispatch(gotoStep4(responseObject.data.newToken));
                }
            }
        });

      }

    })
    .catch( error => {
      this.setState({ errorMessage: 'Password update failed due to network error. Please try again later.' });
    });
  }

  render() {

    return (
      <React.Fragment>

        <LoggedOutHeader />

        <main className="container">

          <h2 className="text-center">Reset Your Password</h2>
          <h4 className="text-center">Step 4 of 4</h4>

          <form id="form-forgot-password" onSubmit={this.onSubmit}>

            <p className="text-center">Choose a new password below.</p>

            <div className="form-group form-group-mediumlarge d-block mx-auto">
              <label htmlFor="password1">Password</label>
              <input type="password" className="form-control" name="password1" id="password1" placeholder="" required maxLength="255" onChange={this.onChange} />
              <div className="help-block">{ AppSettings.passwordFormatDescription }</div>
            </div>

            <div className="form-group form-group-mediumlarge d-block mx-auto">
              <label htmlFor="password2">Re-Enter Password</label>
              <input type="password" className="form-control" name="password2" id="password2" placeholder="" required maxLength="255" onChange={this.onChange} />
            </div>

            <br />
            { this.state.errorMessage !== null ? (<div className="text-center"><br /><p className="error-message"><strong>Error:</strong> { this.state.errorMessage }</p></div>) : (null) }
            <br />

            <div className="form-group text-center">
              <button type="submit" className="btn btn-primary btn-lg btn-wide">Submit &nbsp; <i className="fa fa-caret-right" aria-hidden="true"></i></button>
            </div>

          </form>

          {/* See https://stackoverflow.com/a/55655997/649131 for an explanation of why Link is not used here */}
          <p className="muted text-center"><small>or <a href="/">return to the login page</a></small></p>

        </main>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    username: state.forgotPassword.username,
    token: state.forgotPassword.token
  }
}

export default connect(mapStateToProps)(ForgotPasswordStep4);
