import React, { Component } from 'react';
import LoggedOutHeader from '../../ui/LoggedOutHeader';
import { connect } from 'react-redux';

class ForgotUsernameStep4 extends Component {

  render() {

    return (
      <React.Fragment>

        <LoggedOutHeader />

        <main className="container">

          <h2 className="text-center">Retrieve Username</h2>
          <h4 className="text-center">Step 4 of 4</h4>

            <p className="text-center">Following is a list of usernames associated with your Antietam Broadband account. This list has also been sent to the primary account email address.</p>

            <div class="text-center">
              <ul className="list-group my-4 mx-auto" id="userlist">
                { this.props.users.map( (user, i) => {
                  return(
                    <li className="list-group-item" key={i}>
                      { user.username }
                      <div className="float-right text-center">
                        { user.hoh === 1 ? (<span className="badge badge-primary">Account Owner</span>) : (<span className="badge badge-secondary">Secondary User</span>) }
                      </div>
                    </li>
                  )
                })
              }
              </ul>
            </div>

            <p className="muted text-center"><small>When you are finished viewing this list, you can <a href="/">return to the login page</a></small></p>

        </main>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    users: state.forgotUsername.users
  }
}

export default connect(mapStateToProps)(ForgotUsernameStep4);
