import React from 'react';
import { Link } from 'react-router-dom';
import LoggedOutHeader from './ui/LoggedOutHeader';

const Error404NotFound = () => {
  return (
    <>

      <LoggedOutHeader />

      <main className="container text-center">

        <h1>Error 404 - Page Not Found</h1>

        <p>The requested URL does not exist on this server.</p>
        <br />

        <p className="muted"><small><Link to="/">Go to account dashboard</Link></small></p>
      </main>

    </>
  )
}

export default Error404NotFound
