const OidcSettings = {
  authority: 'https://sso.myactv.net:443/IDBUS/POCTUASS/APILOGIN-OP/OIDC/MD',
  client_id: 'myclientid',
  client_secret: 'noisyJ3wel73',
  redirect_uri: 'https://account.myactv.net/account/dashboard',
  response_type: 'id_token token',
  scope: 'openid',
  post_logout_redirect_uri: 'https://account.myactv.net/account/dashboard',
  loadUserInfo: false
};
// clockSkew: 300

export default OidcSettings;
