import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ModalChangePreferredEmail extends Component {

  render() {
    return (

      <Modal show={this.props.show} onHide={this.props.onClose}>

        <form onSubmit={this.props.onSubmit}>

          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Change Contact Email Address</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="form-group">
              <label htmlFor="newEmail">Enter New Email Address</label>
              <input type="email" className="form-control" name="newEmail" id="newEmail" aria-describedby="newEmailHelpBlock" />
            </div>

            {this.props.errorMessageGeneral && <div className="error-message d-block mt-2">{this.props.errorMessageGeneral}</div>}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Change Email
            </Button>
          </Modal.Footer>

        </form>

      </Modal>

    );
  }
}

export default ModalChangePreferredEmail;
