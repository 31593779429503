const initialState = {
  auth: {
    loggedIn: false,
    username: '',
    accountNo: 0,
    authToken: '',
    hoh: 0,
    adminLevel: 0
  },
  account: {
    hasDataService: null,
    info: {
      accountNo: null,
      homePhone: '',
      title: '',
      firstName: '',
      middleInitial: '',
      lastName: '',
      customerName: '',
      customerTypeCode: '',
      customerCategory: '',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      city: '',
      state: '',
      zip: '',
      preferredEmail: '',
      masterUsername: '',
      extra: {
        maxUsers: 0
      }
    },
    macAddresses: []
  },
  accountBandwidthUsage: {
    usage: [],
    max: 0
  },
  user: {
    activityLog: null,
    userList: [],
    userAddModal: {
      username: '',
      password: ''
    },
    userProfile: {
      username: null,
      accountNo: null,
      hoh: 0,
      createdAt: null,
      updatedAt: null,
      lastLogin: null,
      deletedAt: null,
      adminLevel: null,
      status: '',
      zohoUserId: '',
      zohoEmailAccountId: '',
      originalAccountNo: ''
    }
  },
  forgotPassword: {
    step: 1,
    username: ''
  },
  forgotUsername: {
    step: 1,
    accountId: '',
    zip: ''
  }
};

export { initialState };
