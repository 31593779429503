import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { adminSearchAction } from '../../actions/adminActions';
import * as AdminLevels from '../../config/AdminLevels';

class AccountHeaderCsrSearch extends Component {

  state;

  constructor(props) {
    super(props);

    this.state = {
      searchFieldHasError: false
    };

    this.onSearchSubmit = this.onSearchSubmit.bind(this);
    this.clearSearchFieldError = this.clearSearchFieldError.bind(this);
  }

  clearSearchFieldError() {
    this.setState({ "searchFieldHasError": false });
  }

  onSearchSubmit(e) {
    e.preventDefault();

    this.clearSearchFieldError();

    const searchQuery = e.target.searchQuery.value;

    if (searchQuery && searchQuery.trim().length) {

      /**
       * Dispatch an action to perform the search
       * The action creator returns a Promise. See https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b
       */
      this.props.dispatch(adminSearchAction(searchQuery))
        .then((result) => {

          /* If we got an exact match and the user is TSR or higher, immediately load the account dashboard for that matching account */
          if (result.exactMatch && this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR) {
            const accountNo = result.matches[0].accountNo;
            this.props.history.push('/account/' + accountNo + '/dashboard');
          } else {
            /* For non-exact matches or users below TSR, load the search results page */
            this.props.history.push('/account/admin/search');
          }

        }, () => {
          this.setState({ "searchFieldHasError": true });
        });
    }
  }

  render() {
    return (
      <form className="mt-3" onSubmit={this.onSearchSubmit}>
        <div className="input-group">
          <span className="input-group-text bg-actvOrange text-white border-0">
            <small>CSSR MODE</small>
          </span>
          <input id="searchQuery" onChange={this.clearSearchFieldError} type="search" placeholder="Search for account # or username" aria-describedby="button-addon-search" className={this.state.searchFieldHasError ? 'form-control server-error' : 'form-control'} />
          <button id="button-addon-search" type="submit" className="bg-actvOrange text-white border-0 px-3"><i className="fa fa-search"></i></button>
        </div>
      </form>
    );
  }
}

/* We don't actually pass in any props, but we need mapStateToProps because it also sets this.props.dispatch */
function mapStateToProps(state) {
  return {
    authAdminLevel: state.auth.adminLevel,
  }
}

export default withRouter(connect(mapStateToProps)(AccountHeaderCsrSearch));
