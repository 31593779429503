/* */
import {
  USER_ACTIVITYLOG_CLEARED,
  USER_ACTIVITYLOG_RECEIVED,
  USER_LIST_CLEARED,
  USER_LIST_RECEIVED,
  USER_PROFILE_CLEARED,
  USER_PROFILE_RECEIVED,
  USER_CHANGE_PASSWORD_COMPLETED,
  USER_DELETION_COMPLETED,
  USER_EMAIL_ACTIVATION_COMPLETED,
  USER_ADD_MODAL_USERNAME_PASSWORD_CHANGED,
  USER_CREATION_COMPLETED } from '../actions/types';

const initialState = {
  activityLog: null,
  userList: [],
  userAddModal: {
    username: '',
    password: ''
  },
  userProfile: {
    username: null,
    accountNo: null,
    hoh: 0,
    createdAt: null,
    updatedAt: null,
    lastLogin: null,
    deletedAt: null,
    adminLevel: null,
    status: '',
    zohoUserId: '',
    zohoEmailAccountId: '',
    originalAccountNo: ''
  }
}

/**
 * Some of these actions actually don't modify the state. The only reason
 * they use Redux is so that we can keep them grouped together with
 * all the other user API operations that do modify the state.
 * Like, just to keep everything in one place.
 */

export default function(state = initialState, action) {

  switch(action.type) {

    case USER_ACTIVITYLOG_CLEARED:
      return {
        ...state,
        activityLog: null
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case USER_ACTIVITYLOG_RECEIVED:
      return {
        ...state,
        activityLog: action.payload.data
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case USER_LIST_RECEIVED:
      return {
        ...state,
        userList: action.payload.data
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case USER_LIST_CLEARED:
      return {
        ...state,
        userList: []
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case USER_PROFILE_RECEIVED:
      return {
        ...state,
        userProfile: action.payload.data
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case USER_PROFILE_CLEARED:
      return {
        ...state,
        userProfile: {
          username: null,
          accountNo: null,
          hoh: 0,
          createdAt: null,
          updatedAt: null,
          lastLogin: null,
          deletedAt: null,
          adminLevel: null,
          status: '',
          zohoUserId: '',
          zohoEmailAccountId: '',
          originalAccountNo: ''
        }
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case USER_ADD_MODAL_USERNAME_PASSWORD_CHANGED:

      return {
        ...state,
        userAddModal: action.payload.data
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case USER_CHANGE_PASSWORD_COMPLETED:
      return state;
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case USER_DELETION_COMPLETED:
      return state;
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case USER_EMAIL_ACTIVATION_COMPLETED:
      return state;
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case USER_CREATION_COMPLETED:
      return state;
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    default:
      return state;

  }

}
