import React, {Component} from 'react';
import { connect } from 'react-redux';
import AccountHeader from './AccountHeader';
import { Route, Switch } from 'react-router-dom';
import OidcSettings from '../../config/oidcsettings';
import { UserManager } from 'oidc-client';
import Dashboard from './Dashboard/Dashboard';
import AdminSearchResults from './Admin/AdminSearchResults';
import AdminWelcome from './Admin/AdminWelcome';

import { loginCompletedAction } from '../../actions/authActions'
import * as AdminLevels from '../../config/AdminLevels';

class Account extends Component {

  state;
  signinRedirectCallbackFireCount = 0;

  constructor(props) {
    super(props);
    this.handleSignin = this.handleSignin.bind(this);
    this.onUserLoaded = this.onUserLoaded.bind(this);

    this.userManager = new UserManager(OidcSettings);
    this.userManager.events.addUserLoaded(this.onUserLoaded);
  }

  componentDidMount() {
    if (!this.props.authLoggedIn) {
      this.handleSignin();
    }
  }

  handleSignin() {
    if (window.location.href.includes("#access_token")) {
      this.signinRedirectCallbackFireCount++;
      if (this.signinRedirectCallbackFireCount === 1) {
        this.userManager.signinRedirectCallback().then(() => {
          if (this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR) {
            this.props.history.push('/account/admin');
          } else {
            this.props.history.push('/account/dashboard');
          }
        }).catch(function (err) {
          console.log("Error signinRedirectCallback: ", err);
        });
      }
    } else {
      // prevent Error: No matching state found in storage,
      // which can happen due to duplicate state entries
      this.userManager.clearStaleState(null).then(() => {
          this.userManager.signinRedirect();
          return <p>Signing you in...</p>;
      });
    }
  }

  onUserLoaded(user) {
    this.props.dispatch(loginCompletedAction(user));
  }

  render() {

    return (
      <React.Fragment>
        {this.props.authLoggedIn &&
          <React.Fragment>

            <AccountHeader userManager={this.userManager} />

              {/* Here we load the specific page based on the current URL */}
              <Switch>

                  {/* Admin main dashboard */}
                  <Route exact path='/account/admin'>
                    <AdminWelcome />
                  </Route>

                  {/* Admin search results */}
                  <Route
                    exact path='/account/admin/search'
                    component={AdminSearchResults}
                  />

                  {/* This loads the dashboard for the currently logged-in user. */}
                  {/* The key field is to force the component to remount when the URL path changes */}
                  {/* See https://stackoverflow.com/questions/31813512/is-it-possible-to-only-remount-only-the-new-child-components-on-react-router-tra#answer-31816415 */}
                  <Route
                    exact path='/account/dashboard'
                    key={window.location.href}
                    render={(props) => <Dashboard {...props} accountNo={this.props.authAccountNo} />}
                  />

                  {/* This loads the dashboard for a different account number that is specified in the URL */}
                  <Route
                    exact path='/account/:accountNo/dashboard'
                    key={window.location.href}
                    component={Dashboard} />
                  />

              </Switch>
              {this.props.authAdminLevel > AdminLevels.ADMIN_LEVEL_CUSTOMER && <br />}

          </React.Fragment>
        }

      </React.Fragment>
    )

  }
}

function mapStateToProps(state) {
  return {
    authLoggedIn: state.auth.loggedIn,
    authUsername: state.auth.username,
    authAccountNo: state.auth.accountNo,
    authAdminLevel: state.auth.adminLevel
  }
}

export default connect(mapStateToProps)(Account);
