import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AppSettings from '../../../../config/appsettings';

class ModalChangePassword extends Component {

  state;

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };

    this.resetPasswordToggle = this.resetPasswordToggle.bind(this);
    this.showPasswordToggleClicked = this.showPasswordToggleClicked.bind(this);
  }

  resetPasswordToggle() {
    this.setState(prevState => ({
      showPassword: false
    }));
  }

  showPasswordToggleClicked() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  render() {
    return (

      <Modal show={this.props.show} onShow={this.resetPasswordToggle} onHide={this.props.onClose}>

        <form onSubmit={this.props.onSubmit}>

          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Change Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="form-group">
              <label htmlFor="newPassword">Enter New Password</label>
              <div className="input-group">
                <input type={this.state.showPassword ? 'text' : 'password'} className="form-control" name="newPassword" id="newPassword" aria-describedby="newPasswordHelpBlock" />
                <button type="button" id="showPasswordToggle" onClick={this.showPasswordToggleClicked}><i className={this.state.showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true"></i></button>
            </div>
              <small id="newPasswordHelpBlock" className="form-text text-muted">
                { AppSettings.passwordFormatDescription }
              </small>
            </div>

            {this.props.errorMessageGeneral && <div className="error-message d-block mt-2">{this.props.errorMessageGeneral}</div>}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Change Password
            </Button>
          </Modal.Footer>

        </form>

      </Modal>

    );
  }
}

export default ModalChangePassword;
