import React, { Component } from 'react';
import LoggedOutHeader from '../../ui/LoggedOutHeader';
import { connect } from 'react-redux';
import { gotoStep2 } from '../../../actions/forgotUsernameActions'
import ReCAPTCHA from "react-google-recaptcha";

class ForgotUsernameStep1 extends Component {

  state;
  recaptchaHandle;

  constructor(props) {

    super(props);

    this.state = {
      accountId: '',
      zip: '',
      errorMessage: null,
      gRecaptchaResponse: null
    }

    this.onChange = this.onChange.bind(this);
    this.onRecaptchaChange = this.onRecaptchaChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  onRecaptchaChange(value) {
    this.setState({gRecaptchaResponse: value});
  }

  onSubmit(e) {

    e.preventDefault();

    // clear any existing error messages
    this.setState({errorMessage: null})

    console.log('baseurl is ' + process.env.REACT_APP_API_SERVER_URL);
    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/account/' + Number(this.state.accountId) + '/getVerificationOptions';
    const postData = {
      zip: Number(this.state.zip),
      gRecaptchaResponse: this.state.gRecaptchaResponse
    };

    fetch(url, {
      method: "post",
      body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then(data => {

      if (data.status) {
        this.setState({errorMessage: data.detail})
        this.recaptchaHandle.reset(); // reload ReCAPTCHA so user can re-enter
      } else {
        this.props.dispatch(gotoStep2(this.state.accountId, this.state.zip, data));
      }
    });
  }

  render() {
    return (
      <React.Fragment>

        <LoggedOutHeader />

        <main className="container">

          <h2 className="text-center">Retrieve Username</h2>
          <h4 className="text-center">Step 1 of 4</h4>

          <p className="text-center">Complete the form below to retrieve a list of usernames associated with your Antietam Broadband account.</p>
          <br />

          <form id="form-forgot-username" onSubmit={this.onSubmit}>

            <div className="block-account text-center">

              <div className="form-group d-inline-block mx-auto text-center">
                <label htmlFor="accountId">Enter your account number here</label>
                <input type="text" className="form-control form-control-lg mx-auto" name="accountId" id="accountId" placeholder="000000000" required maxLength="9" value={this.state.accountId} onChange={this.onChange} />
              </div>
            </div>

            <br />

            <div className="form-group form-group-narrow d-block mx-auto text-center">
              <label htmlFor="username">Enter your ZIP code</label>
              <input type="text" className="form-control form-control-lg mx-auto" name="zip" id="zip" placeholder="xxxxx" required maxLength="5" pattern="^\d{5}$" value={this.state.zip} onChange={this.onChange} />
              <div className="help-block">5-digit ZIP associated with your account</div>
            </div>

            <br />
            <div className="text-center">
              <ReCAPTCHA
                style={{display: "inline-block"}}
                sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
                onChange={this.onRecaptchaChange}
                ref={el => { this.recaptchaHandle = el; }}
              />
            </div>

            <br />
            { this.state.errorMessage !== null ? (<div className="text-center"><br /><p className="error-message"><strong>Error:</strong> { this.state.errorMessage }</p></div>) : (null) }
            <br />

            <div className="form-group text-center">
              <button type="submit" className="btn btn-primary btn-lg btn-wide">Continue &nbsp; <i className="fa fa-caret-right" aria-hidden="true"></i></button>
            </div>

          </form>

          {/* See https://stackoverflow.com/a/55655997/649131 for an explanation of why Link is not used here */}
          <p className="muted text-center"><small>or <a href="/">return to the login page</a></small></p>

        </main>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    accountId: state.forgotUsername.accountId,
    zip: state.forgotUsername.zip
  }
}

export default connect(mapStateToProps)(ForgotUsernameStep1);
