import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  activateUserEmailAction,
  clearActivityLogAction,
  clearUserListAction,
  createUserAction,
  deleteUserAction,
  loadActivityLogAction,
  loadUserListAction,
  moveUserToAnotherAccountAction,
  updateUserPasswordAction,
  updateUserAddModalState,
  setUserAsHohAction
} from '../../../actions/userActions';
import { loadDeletedUserListAction } from '../../../actions/deletedUserActions';
import ModalChangePassword from './Modals/ModalChangePassword';
import ModalMoveUserToAnotherAccount from './Modals/ModalMoveUserToAnotherAccount';
import ModalUserActivateEmail from './Modals/ModalUserActivateEmail';
import ModalUserAdd from './Modals/ModalUserAdd';
import ModalUserDelete from './Modals/ModalUserDelete';
import ModalUserSetAsHoh from './Modals/ModalUserSetAsHoh';
import ModalViewActivityLog from './Modals/ModalViewActivityLog';
import UserTypeBadge from '../../ui/UserTypeBadge';
import * as AdminLevels from '../../../config/AdminLevels';

class DashboardUserList extends Component {

  state;

  constructor(props) {
    super(props);

    this.state = {
      currentModal: null,
      currentModalUsername: null,
      modalErrorGeneral: null,
      modalErrorUsername: null,
      modalErrorPassword: null,
      modalBusy: false
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);

    this.openChangePasswordModal = this.openChangePasswordModal.bind(this);
    this.openUserActivateEmailModal = this.openUserActivateEmailModal.bind(this);
    this.openUserAddModal = this.openUserAddModal.bind(this);
    this.openUserDeleteModal = this.openUserDeleteModal.bind(this);
    this.openUserSetAsHohModal = this.openUserSetAsHohModal.bind(this);
    this.openUserMoveToAnotherAccountModal = this.openUserMoveToAnotherAccountModal.bind(this);
    this.openViewActivityLogModal = this.openViewActivityLogModal.bind(this);

    this.onSubmitMoveToAnotherAccountModal = this.onSubmitMoveToAnotherAccountModal.bind(this);
    this.onSubmitPasswordChangeModal = this.onSubmitPasswordChangeModal.bind(this);
    this.onSubmitUserAddModal = this.onSubmitUserAddModal.bind(this);
    this.onSubmitUserActivateEmailModal = this.onSubmitUserActivateEmailModal.bind(this);
    this.onSubmitUserDeleteModal = this.onSubmitUserDeleteModal.bind(this);
    this.onSubmitSetAsHohModal = this.onSubmitSetAsHohModal.bind(this);
  }

  componentDidMount() {
    // Dispatch an action to load the user list from the REST API server
    this.props.dispatch(loadUserListAction(this.props.accountNo));
  }

  componentWillUnmount() {
    // Dispatch actions to clear certain store properties (so they don't appear on the screen the next time the component is loaded)
    this.props.dispatch(clearUserListAction());
    this.props.dispatch(clearActivityLogAction());
  }

  closeAllModals() {
    this.setState({ "currentModal": null });
    this.setState({ "currentModalUsername": null });
    this.setState({ "modalErrorGeneral": null });
    this.setState({ "modalErrorUsername": null });
    this.setState({ "modalErrorPassword": null });
    this.setState({ "modalBusy": false });
    this.props.dispatch(updateUserAddModalState('', '')); // clear out username & password fields in user add modal
    this.props.dispatch(clearActivityLogAction()); // clear out the activity log
  }

  openChangePasswordModal(event) {
    this.setState({ "currentModalUsername": event.target.dataset.username });
    this.setState({ "currentModal": 'changePassword' });
  }

  openUserMoveToAnotherAccountModal(event) {
    this.setState({ "currentModalUsername": event.target.dataset.username });
    this.setState({ "currentModal": 'moveToAnotherAccount' });
  }

  openUserSetAsHohModal(event) {
    this.setState({ "currentModalUsername": event.target.dataset.username });
    this.setState({ "currentModal": 'setAsHoh' });
  }

  openUserDeleteModal(event) {
    this.setState({ "currentModalUsername": event.target.dataset.username });
    this.setState({ "currentModalUserHasEmail": (event.target.dataset.hasemail === 'true') });
    this.setState({ "currentModal": 'userDelete' });
  }

  openUserActivateEmailModal(event) {
    this.setState({ "currentModalUsername": event.target.dataset.username });
    this.setState({ "currentModal": 'userActivateEmail' });
  }

  openUserAddModal() {
    this.setState({ "currentModal": 'userAdd' });
  }

  /**
   * The following methods dispatch an action to perform a user operation via the API server.
   * In each case, the action creator returns a Promise.
   * See https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b
   */

  openViewActivityLogModal(event) {

    event.preventDefault();

    const username = event.target.dataset.username;

    /* Open modal immediately */
    this.setState({ "currentModalUsername": username });
    this.setState({ "currentModal": 'viewActivityLog' });

    /* Load activity log data */
    this.props.dispatch(loadActivityLogAction(username))
      .then(() => {
        /**
         * do nothing - the activity log details will be passed directly from the props of this
         * component to the props of the activity log modal
         */
      }, errorMessage => {
        // If an error was returned, pass it along to the modal
        this.setState({ "modalErrorGeneral": errorMessage });
      });

  }

  onSubmitMoveToAnotherAccountModal(event) {
    event.preventDefault();

    /* Clear any existing error message */
    this.setState({ "modalErrorGeneral": null });

    const username = this.state.currentModalUsername;
    const newAccountNo = event.target.newAccountNo.value;

    this.props.dispatch(moveUserToAnotherAccountAction(username, newAccountNo))
      .then(() => {
        // Close modal and reload user list
        this.closeAllModals();
        this.props.dispatch(loadUserListAction(this.props.accountNo));
      }, errorMessage => {
        this.setState({ "modalErrorGeneral": errorMessage });
      });

  }

  onSubmitSetAsHohModal(event) {
    event.preventDefault();

    /* Clear any existing error message */
    this.setState({ "modalErrorGeneral": null });

    const username = this.state.currentModalUsername;

    this.props.dispatch(setUserAsHohAction(username))
      .then(() => {
        // Close modal and reload user list
        this.closeAllModals();
        this.props.dispatch(loadUserListAction(this.props.accountNo));
      }, errorMessage => {
        this.setState({ "modalErrorGeneral": errorMessage });
      });

  }

  onSubmitUserDeleteModal(event) {
    event.preventDefault();

    /* Clear any existing error message */
    this.setState({ "modalErrorGeneral": null });

    const username = this.state.currentModalUsername;

    this.props.dispatch(deleteUserAction(username))
      .then(() => {
        // Close modal and reload both regular and deleted user lists
        this.closeAllModals();
        this.props.dispatch(loadUserListAction(this.props.accountNo));
        this.props.dispatch(loadDeletedUserListAction(this.props.accountNo));
      }, errorMessage => {
        this.setState({ "modalErrorGeneral": errorMessage });
      });
  }

  onSubmitUserActivateEmailModal(event) {
    event.preventDefault();

    /* Set modal to busy (which will disable submit button) */
    this.setState({ "modalBusy": true });

    /* Clear any existing error message */
    this.setState({ "modalErrorGeneral": null });

    const username = this.state.currentModalUsername;
    const password = event.target.passwordToVerify.value;

    this.props.dispatch(activateUserEmailAction(username,password))
      .then(() => {

        // Close modal and reload user list
        this.closeAllModals();
        this.props.dispatch(loadUserListAction(this.props.accountNo));
      }, errorMessage => {
        /* Rewrite this error message from the API server to be more clear to user */
        errorMessage = errorMessage.replace('User does not exist or password is invalid', 'Password is incorrect');
        this.setState({ "modalErrorGeneral": errorMessage });

        /* Re-enable the submit button */
        this.setState({ "modalBusy": false });
      });
  }

  onSubmitPasswordChangeModal(event) {
    event.preventDefault();

    /* Clear any existing error message */
    this.setState({ "modalErrorGeneral": null });

    const username = this.state.currentModalUsername;
    const newPassword = event.target.newPassword.value;

    this.props.dispatch(updateUserPasswordAction(username, newPassword))
      .then(() => {
        this.closeAllModals();
      }, errorMessage => {
        this.setState({ "modalErrorGeneral": errorMessage });
      });
  }

  onSubmitUserAddModal(event) {

    event.preventDefault();

    /* Set modal to busy (which will disable submit button) */
    this.setState({ "modalBusy": true });

    /* Clear any existing error messages */
    this.setState({ "modalErrorGeneral": null });
    this.setState({ "modalErrorUsername": null });
    this.setState({ "modalErrorPassword": null });

    /* Validate username length */
    if ((this.props.userAddModalUsernameValue.length < 3) || (this.props.userAddModalUsernameValue.length > 32)) {
      this.setState({ "errorMessageUsername": "Username must be between 3 and 32 characters in length" });
      return;
    }

    /* Validate whether the last character of the username is alphanumeric */
    const regex = /[a-z0-9]/;
    if (regex.test(this.props.userAddModalUsernameValue.substr(-1)) === false) {
      this.setState({ "errorMessageUsername": "Username must end with a letter or number" });
      return;
    }

    const username = this.props.userAddModalUsernameValue;
    const password = this.props.userAddModalPasswordValue;
    const accountNo = this.props.accountNo;
    const activateEmail = event.target.activateEmail.checked;

    this.props.dispatch(createUserAction(username, password, accountNo, activateEmail))
      .then(() => {
        // Close modal and reload user list
        this.closeAllModals();
        this.props.dispatch(loadUserListAction(this.props.accountNo));
      }, errorMessage => {

        // Assign the error message to the appropriate modal formfield
        if (errorMessage.includes('username')) {
          this.setState({ "modalErrorUsername": errorMessage });
        } else if (errorMessage.includes('password')) {
          this.setState({ "modalErrorPassword": errorMessage });
        } else {
          this.setState({ "modalErrorGeneral": errorMessage });
        }

        /* Re-enable the submit button */
        this.setState({ "modalBusy": false });

      });
  }

  render() {

    var userList;

    if (this.props.userList && this.props.userList.constructor === Array) {

      userList = this.props.userList.map((user, index) =>
        <React.Fragment key={user.username}>

          <div className="row pt-1">
            <div className="col-md-6 text-start">
              <p><strong>Username</strong><br />{user.username}</p>
              <p><strong>Email</strong><br />
                {user.zohoUserId != null && <>{user.username}@myactv.net</>}
                {user.zohoUserId == null && <>
                  {this.props.hasDataService && <span className="font-italic text-muted">not activated</span>}
                  {!this.props.hasDataService && <span className="font-italic text-muted">not available - account is video-only</span>}
                </>}
              </p>
            </div>
            <div className="col-md-4 text-start">
              <p><strong>User Type</strong><br /><UserTypeBadge hoh={user.hoh} /></p>
              <p><strong>Status</strong><br />{user.status.charAt(0).toUpperCase() + user.status.slice(1) }{user.status === "suspended" && <i className="fa fa-exclamation-triangle ms-1 text-danger" aria-hidden="true"></i>}</p>
            </div>
            <div className="col-md-2 text-start">
              <p className="mb-1"><strong>Actions</strong></p>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" bsPrefix="dashboardUsersActionToggle" className="btn btn-ghost btn-sm dropdown-toggle">
                  Select...
                </Dropdown.Toggle>

                <Dropdown.Menu>

                  {/* Change Password */}
                  {
                    ((this.props.authAccountNo === this.props.accountNo) || (this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR)) &&
                    <Dropdown.Item
                      onClick={this.openChangePasswordModal}
                      data-username={user.username}
                      disabled={user.status === 'migrating'}
                    >Change Password</Dropdown.Item>
                  }

                  {/* Activate Email */}
                  {
                    this.props.hasDataService && user.zohoUserId == null &&
                      ((this.props.authAccountNo === this.props.accountNo) || (this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR)) &&
                        <Dropdown.Item
                          onClick={this.openUserActivateEmailModal}
                          data-username={user.username}
                          disabled={user.status === 'migrating'}
                        >Activate Email</Dropdown.Item>}

                  {/* Move to Another Account */}
                  {
                    (
                      (user.hoh === 0 && this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR) ||
                      (this.props.authAdminLevel === AdminLevels.ADMIN_LEVEL_STAFF_MASTER_ADMIN)
                    )
                    &&
                    <Dropdown.Item
                      onClick={this.openUserMoveToAnotherAccountModal}
                      data-username={user.username}
                      disabled={user.status === 'migrating'}
                      >Move to Another Account</Dropdown.Item>
                  }

                  {/* Set as Account Owner */}
                  {
                    user.hoh === 0 && this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR &&
                    <Dropdown.Item
                      onClick={this.openUserSetAsHohModal}
                      data-username={user.username}
                      disabled={user.status === 'migrating'}
                      >Set as Account Owner</Dropdown.Item>
                  }

                  {/* View Activity Log */}
                  {
                    this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR &&
                    <Dropdown.Item
                      onClick={this.openViewActivityLogModal}
                      data-username={user.username}
                      >View Activity Log</Dropdown.Item>
                  }

                  {/* Delete User */}
                  {
                    user.hoh === 0 && ((this.props.authAccountNo === this.props.accountNo) || (this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR)) &&
                    <Dropdown.Item
                      onClick={this.openUserDeleteModal}
                      data-username={user.username}
                      data-hasemail={user.zohoUserId != null}
                      disabled={user.status === 'migrating'}
                      >Delete User</Dropdown.Item>
                  }

                </Dropdown.Menu>

              </Dropdown>
              <br />
            </div>
          </div>

          {index < (this.props.userList.length - 1) &&
            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>
          }

        </React.Fragment>
      );

    } else {
      userList = '';
    }

    return (
      <>

        <div className="card card-dashcard">

          <div className="card-header">
            <div className="row">
              <div className="col text-start d-flex align-items-center">
                <h2>Users {this.props.userList && <span>({this.props.userList.length} {this.props.account && this.props.account.extra && <span>of {this.props.account.extra.maxUsers}</span>} in use)</span>}</h2>
              </div>
              {this.props.userList && this.props.account && this.props.account.extra && (this.props.account.extra.maxUsers > this.props.userList) && <span></span>}
              <div className="col text-end">
                <button
                  className="btn btn-sm btn-ghost"
                  style={{paddingBottom: '2px'}}
                  onClick={this.openUserAddModal}
                  disabled={!this.props.userList || !this.props.account || !this.props.account.extra || (this.props.userList.length >= this.props.account.extra.maxUsers)}
                  >Add User &nbsp;<i className="fa fa-plus" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>

          <div className="card-body">

            {userList}

          </div>
        </div>

        <ModalUserAdd
          show={this.state.currentModal === "userAdd"}
          onClose={this.closeAllModals}
          onSubmit={this.onSubmitUserAddModal}
          errorMessageGeneral={this.state.modalErrorGeneral}
          errorMessageUsername={this.state.modalErrorUsername}
          errorMessagePassword={this.state.modalErrorPassword}
          modalBusy={this.state.modalBusy} />

        <ModalUserDelete
          username={this.state.currentModalUsername}
          hasEmail={this.state.currentModalUserHasEmail}
          show={this.state.currentModal === "userDelete"}
          onClose={this.closeAllModals}
          onSubmit={this.onSubmitUserDeleteModal}
          errorMessageGeneral={this.state.modalErrorGeneral} />

        <ModalChangePassword
          show={this.state.currentModal === "changePassword"}
          onClose={this.closeAllModals}
          onSubmit={this.onSubmitPasswordChangeModal}
          errorMessageGeneral={this.state.modalErrorGeneral} />

        <ModalMoveUserToAnotherAccount
          show={this.state.currentModal === "moveToAnotherAccount"}
          onClose={this.closeAllModals}
          onSubmit={this.onSubmitMoveToAnotherAccountModal}
          errorMessageGeneral={this.state.modalErrorGeneral} />

        <ModalUserSetAsHoh
          show={this.state.currentModal === "setAsHoh"}
          username={this.state.currentModalUsername}
          onClose={this.closeAllModals}
          onSubmit={this.onSubmitSetAsHohModal}
          errorMessageGeneral={this.state.modalErrorGeneral} />

        <ModalUserActivateEmail
          show={this.state.currentModal === "userActivateEmail"}
          username={this.state.currentModalUsername}
          selfOrChild={this.state.currentModalUsername === this.props.authUsername ? 'self' : 'child'}
          onClose={this.closeAllModals}
          onSubmit={this.onSubmitUserActivateEmailModal}
          errorMessageGeneral={this.state.modalErrorGeneral}
          modalBusy={this.state.modalBusy} />

        <ModalViewActivityLog
          show={this.state.currentModal === "viewActivityLog"}
          username={this.state.currentModalUsername}
          activityLog={this.props.activityLog}
          onClose={this.closeAllModals}
          errorMessageGeneral={this.state.modalErrorGeneral} />
      </>
    )

  }

}

function mapStateToProps(state) {
  return {
    activityLog: state.user.activityLog,
    authAccountNo: state.auth.accountNo,
    authAdminLevel: state.auth.adminLevel,
    authToken: state.auth.authToken,
    authUsername: state.auth.username,
    account: state.account.info,
    userList: state.user.userList,
    userAddModalUsernameValue: state.user.userAddModal.username,
    userAddModalPasswordValue: state.user.userAddModal.password
  }
}

export default connect(mapStateToProps)(DashboardUserList);
