import React, { Component } from 'react';
import { resetForgotPasswordFlow } from '../../../actions/forgotPasswordActions'
import ForgotPasswordStep1 from './ForgotPasswordStep1';
import ForgotPasswordStep2 from './ForgotPasswordStep2';
import ForgotPasswordStep3 from './ForgotPasswordStep3';
import ForgotPasswordStep4 from './ForgotPasswordStep4';
import ForgotPasswordStep5 from './ForgotPasswordStep5';

import { connect } from 'react-redux';

class ForgotPassword extends Component {

  componentDidMount() {
    this.props.dispatch(resetForgotPasswordFlow());
  }

  render() {

    switch (this.props.step) {

      case 1:
        return <ForgotPasswordStep1 />;
        /* eslint-disable no-unreachable */
        break;
        /* eslint-enable no-unreachable */

      case 2:
        return <ForgotPasswordStep2 />;
        /* eslint-disable no-unreachable */
        break;
        /* eslint-enable no-unreachable */

      case 3:
        return <ForgotPasswordStep3 />;
        /* eslint-disable no-unreachable */
        break;
        /* eslint-enable no-unreachable */

      case 4:
        return <ForgotPasswordStep4 />;
        /* eslint-disable no-unreachable */
        break;
        /* eslint-enable no-unreachable */

      case 5:
        return <ForgotPasswordStep5 />;
        /* eslint-disable no-unreachable */
        break;
        /* eslint-enable no-unreachable */

      default:
        return <ForgotPasswordStep1 />;
    }

  }

}

function mapStateToProps(state) {
  return {
    step: state.forgotPassword.step
  }
}

export default connect(mapStateToProps)(ForgotPassword);
