import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { updateUserAddModalState } from '../../../../actions/userActions';
import AppSettings from '../../../../config/appsettings';

class ModalUserAdd extends Component {

  state;

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };

    this.showPasswordToggleClicked = this.showPasswordToggleClicked.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onUsernameInput = this.onUsernameInput.bind(this);
  }

  showPasswordToggleClicked() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  /**
   * Whenever the user types input, this dispatches an action to update the
   * username and password in the store. We have to do this so the values will
   * be available to the calling component when the user clicks submit.
   */
  onUsernameInput(e) {

    /**
     * Only change the value in the username field if it matches allowable
     * pattern. First character must be a letter. Additional characters must
     * be a-z, 0-9, . or _ .  The final character must be a-z or 0-9, however
     * we can't enforce that here.  That will have to be checked on submit.
     */
    var regex;
    if (e.target.value.length === 0) {

      /* Dispatch action to set username to empty. Note that password is passed along unchanged */
      this.props.dispatch(updateUserAddModalState('', this.props.userAddModalPasswordValue));
      return;
    } else if (e.target.value.length === 1) {
      regex = /^[a-z]{1}$/;
    } else {
      regex = /^[a-z][a-z0-9._-]{1,31}$/;
    }

    if (regex.test(e.target.value)) {
      /* Dispatch action to change username. Note that password is passed along unchanged */
      this.props.dispatch(updateUserAddModalState(e.target.value, this.props.userAddModalPasswordValue));
    }

  }

  /* Similar deal with this one, except it's attached to onChange instead of onInput */
  onPasswordChange(e) {
    this.props.dispatch(updateUserAddModalState(this.props.userAddModalUsernameValue, e.target.value));
  }

  render() {
    return (

      <Modal show={this.props.show} onHide={this.props.onClose}>

        <form onSubmit={this.props.onSubmit}>

          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="form-group">
              <label htmlFor="username">Choose a Username</label>
              <input type="text" className="form-control" name="usernameToAdd" id="usernameToAdd" autoComplete="off" autoCapitalize="off" value={this.props.userAddModalUsernameValue} onInput={this.onUsernameInput} onChange={() => { /* do nothing */ }} aria-describedby="usernameHelpBlock" />
              <small id="usernameHelpBlock" className="form-text text-muted">
                3-32 chars; lowercase letters, numbers, periods, underscores; must begin with letter
              </small>
              {this.props.errorMessageUsername && <div className="error-message d-block mt-2">{this.props.errorMessageUsername}</div>}
            </div>

            <div className="form-group">
              <label htmlFor="passwordToAdd">Choose a Password</label>
              <div className="input-group">
                <input type={this.state.showPassword ? 'text' : 'password'} className="form-control" name="passwordToAdd" id="passwordToAdd" aria-describedby="passwordHelpBlock" onChange={this.onPasswordChange} />
                <button type="button" id="showPasswordToggle" onClick={this.showPasswordToggleClicked}><i className={this.state.showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true"></i></button>
            </div>
              <small id="passwordHelpBlock" className="form-text text-muted">
                { AppSettings.passwordFormatDescription }
              </small>
              {this.props.errorMessagePassword && <div className="error-message d-block mt-2">{this.props.errorMessagePassword}</div>}
            </div>

            <div className="form-check">
              <div>
                <input className="form-check-input" type="checkbox" value="1" name="activateEmail" id="activateEmail" />
                <label className="form-check-label" htmlFor="activateEmail">Activate email service for this user</label>
              </div>
              <small id="passwordHelpInline" className="text-muted">
                {this.props.userAddModalUsernameValue.length <3 && <>If checked, a <span className="font-italic">username</span>@myactv.net email address will be created.</>}
                {this.props.userAddModalUsernameValue.length >= 3 && <>If checked, the email address <strong>{this.props.userAddModalUsernameValue}@myactv.net</strong> will be created.</>}
              </small>
            </div>

            {this.props.errorMessageGeneral && <div className="error-message d-block mt-2">{this.props.errorMessageGeneral}</div>}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose} disabled={this.props.modalBusy}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={this.props.modalBusy}>
              Create User
            </Button>
          </Modal.Footer>

        </form>

      </Modal>

    );
  }
}

function mapStateToProps(state) {
  return {
    userAddModalUsernameValue: state.user.userAddModal.username,
    userAddModalPasswordValue: state.user.userAddModal.password
  }
}

export default connect(mapStateToProps)(ModalUserAdd);
