import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';
import { connect } from 'react-redux';

/* Import page components */
import RootUrlRedirector from './components/utilities/RootUrlRedirector';
import Error404NotFound from './components/Error404NotFound';
/* import Bandwidth from './components/Bandwidth'; */
import Account from './components/Account/Account';
import Forgot from './components/Forgot/Forgot';

class App extends Component {

  render() {

    const appClasses = 'App ' + (this.props.authLoggedIn ? 'logged-in' : 'not-logged-in');

    return (

      <div className={appClasses}>

        <BrowserRouter>

          <Switch>

            {/* Paths that are NOT protected by authentication */}
            <Route exact path="/" component={RootUrlRedirector} />
            <Route exact path="/errors/404-not-found" component={Error404NotFound} />
            <Route path="/forgot" component={Forgot} />

            {/* All "logged in" views are wrapped in the Account component */}
            <Route path="/account" component={Account} />

          </Switch>

        </BrowserRouter>

      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    authLoggedIn: state.auth.loggedIn
  }
}

export default connect(mapStateToProps)(App);
