/* */
import { FORGOTPASSWORD_RESET_FLOW, FORGOTPASSWORD_GOTO_STEP2, FORGOTPASSWORD_GOTO_STEP3, FORGOTPASSWORD_GOTO_STEP4, FORGOTPASSWORD_GOTO_STEP5 } from '../actions/types';

const initialState = {
  step: 1,
  username: ''
}

export default function(state = initialState, action) {

  switch(action.type) {

    case FORGOTPASSWORD_RESET_FLOW:
      return {
        ...state,
        step: 1,
        username: ''
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case FORGOTPASSWORD_GOTO_STEP2:
      return {
        ...state,
        step: 2,
        username: action.payload.username,
        verificationOptions: action.payload.verificationOptions
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case FORGOTPASSWORD_GOTO_STEP3:
      return {
        ...state,
        step: 3,
        sentTo: action.payload.sentTo,
        sentVia: action.payload.sentVia
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case FORGOTPASSWORD_GOTO_STEP4:
      return {
        ...state,
        step: 4,
        token: action.payload.token
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case FORGOTPASSWORD_GOTO_STEP5:
      return {
        ...state,
        step: 5
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    default:
      return state;

  }

}
