import React, { Component } from 'react';
import LoggedOutHeader from '../../ui/LoggedOutHeader';
import { connect } from 'react-redux';
import { gotoStep2 } from '../../../actions/forgotPasswordActions'
import ReCAPTCHA from "react-google-recaptcha";

class ForgotPasswordStep1 extends Component {

  state;
  recaptchaHandle;

  constructor(props) {

    super(props);

    this.state = {
      username: '',
      gRecaptchaResponse: '',
      errorMessage: null
    }

    this.onChange = this.onChange.bind(this);
    this.onRecaptchaChange = this.onRecaptchaChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  onRecaptchaChange(value) {
    this.setState({gRecaptchaResponse: value});
  }

  onSubmit(e) {

    e.preventDefault();

    // clear any existing error messages
    this.setState({errorMessage: null})

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user/' + this.state.username.trim().toLowerCase() + '/getVerificationOptions';
    const postData = {
      gRecaptchaResponse: this.state.gRecaptchaResponse
    };

    fetch(url, {
      method: "post",
      body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then(data => {

      if (data.status) {
        this.setState({errorMessage: data.detail})
        this.recaptchaHandle.reset(); // reload ReCAPTCHA so user can re-enter
      } else {
        this.props.dispatch(gotoStep2(this.state.username, data));
      }
    });

  }

  render() {

    return (
      <React.Fragment>

        <LoggedOutHeader />

        <main className="container">

          <h2 className="text-center">Reset Your Password</h2>
          <h5 className="text-center">Step 1 of 4</h5>
          <br />

          <form id="form-forgot-password" onSubmit={this.onSubmit}>

            <div className="block-username">
              <div className="form-group form-group-medium d-block mx-auto">
                <label htmlFor="username">Enter Username</label>
                <input type="text" className="form-control" name="username" id="username" placeholder="" required maxLength="32" value={this.state.username} onChange={this.onChange} />
              </div>
            </div>

            <br />
            <div className="text-center">
              <ReCAPTCHA
                style={{display: "inline-block"}}
                sitekey={process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY}
                onChange={this.onRecaptchaChange}
                ref={el => { this.recaptchaHandle = el; }}
              />
            </div>

            <br />
            { this.state.errorMessage !== null ? (<div className="text-center"><br /><p className="error-message"><strong>Error:</strong> { this.state.errorMessage }</p></div>) : (null) }
            <br />

            <div className="form-group text-center">
              <button type="submit" className="btn btn-primary btn-lg btn-wide">Continue &nbsp; <i className="fa fa-caret-right" aria-hidden="true"></i></button>
            </div>

          </form>

          {/* See https://stackoverflow.com/a/55655997/649131 for an explanation of why Link is not used here */}
          <p className="muted text-center"><small>or <a href="/">return to the login page</a></small></p>

        </main>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    username: state.forgotPassword.username
  }
}

export default connect(mapStateToProps)(ForgotPasswordStep1);
