import React, {Component} from 'react';
import { connect } from 'react-redux';

class AdminWelcome extends Component {

  render() {

    return (

      <main className="container py-4">

        <div className="row">

          <div className="col-12">

            <div className="card card-dashcard">

              <div className="card-header">
                <h2>CSSR Account Management</h2>
              </div>

              <div className="card-body">
                <p className="lead">Welcome to the Antietam Broadband CSSR Account Management system.</p>
              </div>

            </div>

          </div>

        </div>

      </main>

    )
  }

}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps)(AdminWelcome);
