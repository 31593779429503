import React, {Component} from 'react';
import { connect } from 'react-redux';
import DashboardBandwidthForm from './DashboardBandwidthForm';
import DashboardDeletedUserList from './DashboardDeletedUserList';
import DashboardMasterAccountInfo from './DashboardMasterAccountInfo';
import DashboardPreferredEmail from './DashboardPreferredEmail';
import DashboardUserList from './DashboardUserList';
import DashboardUserProfile from './DashboardUserProfile';
import { clearHasDataServiceAction, checkForDataServiceAction } from '../../../actions/accountActions';
import LoadingIndicator from '../../ui/LoadingIndicator';
import * as AdminLevels from '../../../config/AdminLevels';

class Dashboard extends Component {

  state;

  constructor(props) {
    super(props);

    this.state = {
      accountNo: null
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
  }

  componentDidMount() {

    var accountNoToLoad;

    if (typeof(this.props.match.params.accountNo) === "undefined") {

      /**
       * The accountNo was NOT passed to us as a dynamic URL param, so we'll
       * load the dashboard for the currently logged-in user
       */
      accountNoToLoad = this.props.authAccountNo;
    } else {
      /**
       * A specific accountNo was passed to us as a dynamic URL param, so we'll
       * load that dashboard.
       */
       accountNoToLoad = this.props.match.params.accountNo;
    }

    /* Update our local state with this account number... */
    this.setState({ "accountNo": accountNoToLoad }, () => {

      /**
       * ...and then dispatch an action to determine whether this account has data service.
       * The action creator returns a Promise. See https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b
       */
      this.props.dispatch(checkForDataServiceAction(accountNoToLoad))
        .then(() => {
          // success
        }, errorMessage => {
          // failure
          // this.setState({ "modalErrorGeneral": errorMessage });
          alert(errorMessage);
        });

    });

  }

  componentWillUnmount() {
    // Dispatch an action to clear the accountHasDataService boolean (so it doesn't affect the render the next time the component is loaded)
    this.props.dispatch(clearHasDataServiceAction());
  }

  render() {

    /* Wait until we have the accountNo and accountHasDataService boolean before rendering the content */
    if (this.state.accountNo === null || this.props.accountHasDataService === null) {

      return (
        <>
          <main className="container text-center">
            <div className="mt-5 pt-5">
              <LoadingIndicator />
            </div>
          </main>
        </>
      );

    } else {

      return (
        <>
          <main className="container py-4">

            <div className="row">

                <div className="col-lg-4">
                  {this.state.accountNo && <DashboardMasterAccountInfo accountNo={this.state.accountNo} key={'DashboardMasterAccountInfo' + this.state.accountNo} />}
                  <br />
                  {this.state.accountNo && <DashboardPreferredEmail accountNo={this.state.accountNo} key={'DashboardPreferredEmail' + this.state.accountNo} />}
                  {this.props.accountHasDataService && 
                    <>
                      <br />
                      {this.state.accountNo && <DashboardBandwidthForm accountNo={this.state.accountNo} key={'DashboardBandwidthForm' + this.state.accountNo} />}
                    </>
                  }
                  <br />
                </div>

                <div className="col-lg-8">

                  {/* Video-only customers */}
                  {!this.props.accountHasDataService &&
                    <>

                    {/* Admins see the user list */}
                    {(this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_CSSR) &&
                      <>
                        <DashboardUserList accountNo={this.state.accountNo} hasDataService={false} key={'DashboardUserList' + this.state.accountNo} /><br />
                        <DashboardDeletedUserList accountNo={this.state.accountNo} key={'DashboardDeletedUserList' + this.state.accountNo} />
                      </>
                    }

                    {/* Logged-in video-only customer sees a single-user view */}
                    {(this.props.authAdminLevel === AdminLevels.ADMIN_LEVEL_CUSTOMER) &&
                      <>
                        <DashboardUserProfile username={this.props.authUsername} hasDataService={false} key={this.props.authUsername} />
                      </>
                    }

                    </>
                  }

                  {/* Data customers */}
                  {this.props.accountHasDataService &&
                    <>

                      {/* HOH/admin users see user list and deleted user list */}
                      {(this.props.hoh === '1' || this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_CSSR) &&
                        <>
                          <DashboardUserList accountNo={this.state.accountNo} hasDataService={true} key={'DashboardUserList' + this.state.accountNo} /><br />
                          <DashboardDeletedUserList accountNo={this.state.accountNo} key={'DashboardDeletedUserList' + this.state.accountNo} />
                        </>
                      }

                      {/* Non-admin child users get the single profile view */}
                      {!(this.props.hoh === '1' || this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_CSSR) &&
                        <>
                          <DashboardUserProfile username={this.props.authUsername} hasDataService={true} key={'DashboardUserProfile' + this.props.authUsername} />
                        </>
                      }

                    </>
                  }

                  <br />

                </div>

              </div>

          </main>
        </>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    authLoggedIn: state.auth.loggedIn,
    authUsername: state.auth.username,
    authAccountNo: state.auth.accountNo,
    authAdminLevel: state.auth.adminLevel,
    hoh: state.auth.hoh,
    accountHasDataService: state.account.hasDataService
  }
}

export default connect(mapStateToProps)(Dashboard);
