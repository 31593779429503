import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ModalUserDelete extends Component {

  render() {
    return (

      <Modal show={this.props.show} onHide={this.props.onClose}>

        <form onSubmit={this.props.onSubmit}>

          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Delete user</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              {/* User has email */}
              {this.props.hasEmail &&
                <>
                  <div className="warning">
                    <h2 className="text-center">WARNING!</h2>
                    <p>Deleting this user will also delete the email address <strong>{this.props.username}@myactv.net</strong>. The email address will immediately stop working.</p>
                    <p>Existing messages will be stored for seven days.  During this period, the account owner can recover the messages by undeleting the user. This can be done under the &quot;'Deleted Users&quot; section on this page.</p>
                    <p>After seven days, all messages will be PERMANENTLY DELETED and CANNOT BE RECOVERED.</p>
                    <p className="text-center"><strong>Are you sure you want to delete user '{this.props.username}'?</strong></p>
                  </div>
                </>
              }

              {/* User doesn't have email */}
              {!this.props.hasEmail &&
                <>
                  <p>The user <strong>{this.props.username}</strong> will be deleted immediately.</p>
                  <p>It will be possible to undelete the user for seven days after deletion. This can be done under the &quot;'Deleted Users&quot; section on this page. After seven days, the user will be purged permanently and undeletion will no longer be possible.</p>
                  <p className="text-center"><strong>Are you sure you want to delete user '{this.props.username}'?</strong></p>
                </>
              }

            {this.props.errorMessageGeneral && <div className="error-message d-block mt-2">{this.props.errorMessageGeneral}</div>}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" onClick={this.props.onSubmit}>
              Delete User
            </Button>
          </Modal.Footer>

        </form>

      </Modal>

    );
  }
}

export default ModalUserDelete;
