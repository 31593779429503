/*
 * action types
 */

/* Auth */
export const AUTH_LOGIN_COMPLETED = 'AUTH_LOGIN_COMPLETED';
export const AUTH_LOGOUT_COMPLETED = 'AUTH_LOGOUT_COMPLETED';

/* Forgot username */
export const FORGOTUSERNAME_RESET_FLOW = 'FORGOTUSERNAME_RESET_FLOW';
export const FORGOTUSERNAME_GOTO_STEP2 = 'FORGOTUSERNAME_GOTO_STEP2';
export const FORGOTUSERNAME_GOTO_STEP3 = 'FORGOTUSERNAME_GOTO_STEP3';
export const FORGOTUSERNAME_GOTO_STEP4 = 'FORGOTUSERNAME_GOTO_STEP4';

/* Forgot password */
export const FORGOTPASSWORD_RESET_FLOW = 'FORGOTPASSWORD_RESET_FLOW';
export const FORGOTPASSWORD_GOTO_STEP2 = 'FORGOTPASSWORD_GOTO_STEP2';
export const FORGOTPASSWORD_GOTO_STEP3 = 'FORGOTPASSWORD_GOTO_STEP3';
export const FORGOTPASSWORD_GOTO_STEP4 = 'FORGOTPASSWORD_GOTO_STEP4';
export const FORGOTPASSWORD_GOTO_STEP5 = 'FORGOTPASSWORD_GOTO_STEP5';

/* Account */
export const ACCOUNT_HASDATASERVICE_CLEARED = 'ACCOUNT_HASDATASERVICE_CLEARED';
export const ACCOUNT_HASDATASERVICE_RECEIVED = 'ACCOUNT_HASDATASERVICE_RECEIVED';
export const ACCOUNT_INFO_CLEARED = 'ACCOUNT_INFO_CLEARED';
export const ACCOUNT_INFO_RECEIVED = 'ACCOUNT_INFO_RECEIVED';
export const ACCOUNT_MACADDRESSES_RECEIVED = 'ACCOUNT_MACADDRESSES_RECEIVED';
export const ACCOUNT_MACADDRESSES_CLEARED = 'ACCOUNT_MACADDRESSES_CLEARED';
export const ACCOUNT_UPDATE_PREFERRED_EMAIL_COMPLETED = 'ACCOUNT_UPDATE_PREFERRED_EMAIL_COMPLETED';

/* Account bandwidth */
export const ACCOUNT_BANDWIDTH_DATA_CLEARED = 'ACCOUNT_BANDWIDTH_DATA_CLEARED';
export const ACCOUNT_BANDWIDTH_DATA_RECEIVED = 'ACCOUNT_BANDWIDTH_DATA_RECEIVED';

/* Users */
export const USER_ACTIVITYLOG_CLEARED = 'USER_ACTIVITYLOG_CLEARED';
export const USER_ACTIVITYLOG_RECEIVED = 'USER_ACTIVITYLOG_RECEIVED';
export const USER_LIST_RECEIVED = 'USER_LIST_RECEIVED';
export const USER_LIST_CLEARED = 'USER_LIST_CLEARED';
export const USER_MOVE_ACCOUNTNO_COMPLETED = 'USER_MOVE_ACCOUNTNO_COMPLETED';
export const USER_PROFILE_RECEIVED = 'USER_PROFILE_RECEIVED';
export const USER_PROFILE_CLEARED = 'USER_PROFILE_CLEARED';
export const USER_ADD_MODAL_USERNAME_PASSWORD_CHANGED = 'USER_ADD_MODAL_USERNAME_PASSWORD_CHANGED';
export const USER_CHANGE_PASSWORD_COMPLETED = 'USER_CHANGE_PASSWORD_COMPLETED';
export const USER_CREATION_COMPLETED = 'USER_CREATION_COMPLETED';
export const USER_DELETION_COMPLETED = 'USER_DELETION_COMPLETED';
export const USER_EMAIL_ACTIVATION_COMPLETED = 'USER_EMAIL_ACTIVATION_COMPLETED';
export const USER_SET_AS_HOH_COMPLETED = 'USER_SET_AS_HOH_COMPLETED';

/* Deleted Users */
export const DELETED_USER_LIST_RECEIVED = 'DELETED_USER_LIST_RECEIVED';
export const DELETED_USER_LIST_CLEARED = 'DELETED_USER_LIST_CLEARED';
export const DELETED_USER_UNDELETION_COMPLETED = 'DELETED_USER_UNDELETION_COMPLETED';

/* Admin */
export const ADMIN_SEARCH_RESULTS_RECEIVED = 'ADMIN_SEARCH_RESULTS_RECEIVED';
