import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import { connect } from 'react-redux';
import {
  clearMacAddressesAction,
  loadMacAddressesAction
} from '../../../actions/accountActions';
import {
  clearBandwidthDataAction,
  loadBandwidthDataAction
} from '../../../actions/accountBandwidthActions';
import ModalBandwidth from './Modals/ModalBandwidth';

import LoadingIndicator from '../../ui/LoadingIndicator';

class DashboardBandwidthForm extends Component {

  state;

  constructor(props) {
    super(props);

    this.state = {
      currentModal: null,
      modalErrorGeneral: null,
      modalBusy: false,
      selectedMacAddress: null,
      bandwidthData: null,
      bandwidthFormErrorMessage: null,
      currentPeriodDetails: null,
      googleChartsDataCurrent: null,
      googleChartsDataHistory: null
    };

    this.closeAllModals = this.closeAllModals.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.viewBandwidthUsageButtonClicked = this.viewBandwidthUsageButtonClicked.bind(this);
    this.selectedMacAddressChanged = this.selectedMacAddressChanged.bind(this);
    this.setUpBandwidthForm = this.setUpBandwidthForm.bind(this);
  }

  componentDidMount() {
    this.setUpBandwidthForm();
  }

  componentWillUnmount() {
    // Dispatch an action to clear the list of MAC addresses
    this.props.dispatch(clearBandwidthDataAction());
    this.props.dispatch(clearMacAddressesAction());
  }

  selectedMacAddressChanged(event) {
    this.setState({ "selectedMacAddress": event.target.value });
  }

  viewBandwidthUsageButtonClicked(macAddress) {

    // Open the modal immediately
    this.setState({ "currentModal": "bandwidth" });

    // Load bandwidth data
    this.props.dispatch(loadBandwidthDataAction(this.props.accountNo, macAddress))
      .then(() => {
        /**
         * On successful load, convert received data to the object structure
         * needed by the Google Charts React component.  Use temp variables
         * so the chart doesn't display before the conversion is complete.
         */
         var googleChartsDataCurrent = [];
         var googleChartsDataHistoryTemp = [];

         // add headings for current chart
         googleChartsDataCurrent.push([
           'Billing Period',
           'Used Bandwidth',
           { role: 'annotation' },
           'Remaining Bandwidth',
           { role: 'annotation' }
         ]);

         // reverse the array so that the most recent period is displayed first
         this.props.accountBandwidthUsage.usage.reverse();

         // take the first array element and map it into the current chart
         const currentPeriod = this.props.accountBandwidthUsage.usage.shift();
         googleChartsDataCurrent.push([
           '',
           Math.ceil(currentPeriod.usedBytes),
           currentPeriod.usedTxt,
           Math.ceil(currentPeriod.remainingBytes),
           currentPeriod.remainingTxt
         ]);

         // Copy the first period raw data into the local state
         this.setState({ "currentPeriodDetails": currentPeriod });

         // add headings for history chart
         googleChartsDataHistoryTemp.push([
           'Billing Period',
           'Used Bandwidth',
           { role: 'annotation' }
         ]);

         this.props.accountBandwidthUsage.usage.forEach(billingPeriod => {
            googleChartsDataHistoryTemp.push([
              billingPeriod.period,
              Math.ceil( billingPeriod.usedBytes ),
              billingPeriod.usedTxt
            ]);
         });

          /*
         this.props.accountBandwidthUsage.usage.map(billingPeriod => {

            googleChartsDataHistoryTemp.push([
              billingPeriod.period,
              Math.ceil( billingPeriod.usedBytes ),
              billingPeriod.usedTxt
            ]);

         })
         */

         /* Copy the assembled arrays into the local state */
         this.setState({ "googleChartsDataCurrent": googleChartsDataCurrent });
         this.setState({ "googleChartsDataHistory": googleChartsDataHistoryTemp });

      }, errorMessage => {
        // If an error was returned, pass it along to the modal
        this.setState({ "modalErrorGeneral": errorMessage });
      });
  }

  closeAllModals() {
    this.setState({ "modalErrorGeneral": null });
    this.setState({ "currentModal": null });
    this.setState({ "currentPeriodDetails": null });
    this.setState({ "googleChartsDataCurrent": null });
    this.setState({ "googleChartsDataHistory": null });
    this.props.dispatch(clearBandwidthDataAction());
  }

  /**
   * The following methods dispatch an action to perform a user operation via the API server.
   * In each case, the action creator returns a Promise.
   * See https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b
   */
  setUpBandwidthForm() {
    this.props.dispatch(loadMacAddressesAction(this.props.accountNo))
      .then(() => {
        // nothing to do in case of success
      }, errorMessage => {
        console.log('Got error: ' + errorMessage);
        this.setState({ "bandwidthFormErrorMessage": errorMessage });
      });
  }

  render() {

    return (
      <>

        <div className="card card-dashcard">

          <div className="card-header">
            <div className="row">
              <div className="col text-start d-flex align-items-center">
                <h2>Bandwidth Usage</h2>
              </div>
            </div>
          </div>

          <div className="card-body">

            {/* Empty list: presumably still loading */}
            {this.props.macAddresses.length === 0 && !this.state.bandwidthFormErrorMessage && <div className="text-center mt-4"><LoadingIndicator /></div>}

            {/* Only one MAC address: show a button */}
            {this.props.macAddresses.length === 1 && <>
              <div className="text-center">
                <button className="btn btn-default" onClick={() => this.viewBandwidthUsageButtonClicked(this.props.macAddresses[0])}>View Bandwidth Usage &nbsp; <i className="fa fa-bar-chart" aria-hidden="true"></i></button>
               </div>
            </>}

            {/* More than one MAC address: show a dropdown list */}
            {this.props.macAddresses.length > 1 && <>
              <form onSubmit={e => {e.preventDefault()}} className="text-center">
                <select id="selectMacAddress" onChange={this.selectedMacAddressChanged} className="form-control form-control-sm">
                  <option value="">Select modem MAC address</option>
                  {
                    this.props.macAddresses.map(macAddress =>
                        <option key={macAddress} value={macAddress}>{macAddress}</option>
                    )
                  }
                </select>
                <br />
                <button className="btn btn-default" disabled={!this.state.selectedMacAddress} onClick={() => this.viewBandwidthUsageButtonClicked(this.state.selectedMacAddress)}>View Bandwidth Usage &nbsp; <i className="fa fa-bar-chart" aria-hidden="true"></i></button>
              </form>
            </>}

            {/* If there was an error, display it here */}
            <Alert variant="danger" show={this.state.bandwidthFormErrorMessage}><strong>Error:</strong> { this.state.bandwidthFormErrorMessage }</Alert>

          </div>
        </div>

        <ModalBandwidth
          show={this.state.currentModal === "bandwidth"}
          currentPeriodDetails={this.state.currentPeriodDetails}
          selectedMacAddress={this.state.selectedMacAddress}
          googleChartsDataCurrent={this.state.googleChartsDataCurrent}
          googleChartsDataHistory={this.state.googleChartsDataHistory}
          onClose={this.closeAllModals}
          errorMessageGeneral={this.state.modalErrorGeneral} />

      </>
    )

  }

}

function mapStateToProps(state) {
  return {
    macAddresses: state.account.macAddresses,
    accountBandwidthUsage: state.accountBandwidthUsage
  }
}

export default connect(mapStateToProps)(DashboardBandwidthForm);
