import { AUTH_LOGIN_COMPLETED } from './types';
// import store from "../store";

export const loginCompletedAction = (user) => {

  /* Uncomment to debug
  console.log('in loginCompletedAction. here is user:');
  console.log(user);
  console.log(user.profile);
  */

  return {
    type: AUTH_LOGIN_COMPLETED,
    payload: {
      loggedIn: true,
      username: user.profile.sub,
      hoh: user.profile.hoh,
      accountNo: user.profile.accountNo,
      authToken: user.id_token,
      adminLevel: parseInt(user.profile.adminLevel)
    }
  };

}
