import { FORGOTPASSWORD_RESET_FLOW, FORGOTPASSWORD_GOTO_STEP2, FORGOTPASSWORD_GOTO_STEP3, FORGOTPASSWORD_GOTO_STEP4, FORGOTPASSWORD_GOTO_STEP5 } from './types';

export const resetForgotPasswordFlow = () => {
  return {
    type: FORGOTPASSWORD_RESET_FLOW
  };
}

export const gotoStep2 = (username, verificationOptions) => {
  return {
    type: FORGOTPASSWORD_GOTO_STEP2,
    payload: {
        username: username,
        verificationOptions: verificationOptions
    }
  };
}

export const gotoStep3 = (sentTo, sentVia) => {
  return {
    type: FORGOTPASSWORD_GOTO_STEP3,
    payload: {
        sentTo: sentTo,
        sentVia: sentVia
    }
  };
}

export const gotoStep4 = (token) => {
  return {
    type: FORGOTPASSWORD_GOTO_STEP4,
    payload: {
        token: token
    }
  };
}

export const gotoStep5 = () => {
  return {
    type: FORGOTPASSWORD_GOTO_STEP5
  };
}
