import React, {Component} from 'react';
import { connect } from 'react-redux';
import Logo from '../ui/Logo';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import AccountHeaderCsrSearch from './AccountHeaderCsrSearch';
import * as AdminLevels from '../../config/AdminLevels';

class AccountHeader extends Component {

  state;

  constructor(props) {
    super(props);
    this.signOutClicked = this.signOutClicked.bind(this);
    this.onLogoClicked = this.onLogoClicked.bind(this);
  }

  signOutClicked() {
    this.props.userManager.signoutRedirect()
        .catch(function (error) {
            console.error('error while signing out user', error);
        });
  }

  onLogoClicked(e) {
    e.preventDefault();
    if (this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_CSSR) {
      this.props.history.push('/account/admin');
    } else {
      this.props.history.push('/account/dashboard');
    }
  }

  render() {

    if (this.props.authLoggedIn) {

      return (
        <header>
          <div className="container">
            <div className="row">
              <div className="col-md-6 pt-4 pb-1 pb-md-4 d-flex align-items-center">
                <div className="flex-grow-1 text-center text-md-start">
                  {this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_CSSR && <Link to="/account/admin"><Logo /></Link>}
                  {this.props.authAdminLevel < AdminLevels.ADMIN_LEVEL_CSSR && <Link to="/account/dashboard"><Logo /></Link>}
                </div>
              </div>
              <div className="col-md-6 col-usertools py-4 d-flex align-items-center">
                <div className="flex-grow-1">
                  <p className="text-center text-md-end pb-3 pb-md-0 mb-0 text-white">Signed in as <strong>{this.props.authUsername}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;<button className="plainLink px-0" onClick={this.signOutClicked}>Sign Out</button></p>
                  {this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_CSSR && <AccountHeaderCsrSearch />}
                </div>
              </div>
            </div>
          </div>
        </header>
      )

    }

  }

}

function mapStateToProps(state) {
  return {
    authLoggedIn: state.auth.loggedIn,
    authUsername: state.auth.username,
    authAdminLevel: state.auth.adminLevel
  }
}

export default withRouter(connect(mapStateToProps)(AccountHeader));
