/* */
import { AUTH_LOGIN_COMPLETED } from '../actions/types';
// import { AUTH_LOGOUT_COMPLETED } from '../actions/types';

const initialState = {
  account: {}
}

export default function(state = initialState, action) {

  switch(action.type) {

    case AUTH_LOGIN_COMPLETED:

      return {
        ...state,
        authToken: action.payload.authToken,
        hoh: action.payload.hoh,
        loggedIn: action.payload.loggedIn,
        username: action.payload.username,
        accountNo: action.payload.accountNo,
        adminLevel: action.payload.adminLevel
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    default:
      return state;
  }

}
