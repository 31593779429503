/* */
import {
  DELETED_USER_LIST_CLEARED,
  DELETED_USER_LIST_RECEIVED
} from '../actions/types';

const initialState = {
  deletedUserList: []
}

/**
 * Some of these actions actually don't modify the state. The only reason
 * they use Redux is so that we can keep them grouped together with
 * all the other user API operations that do modify the state.
 * Like, just to keep everything in one place.
 */

export default function(state = initialState, action) {

  switch(action.type) {

    case DELETED_USER_LIST_RECEIVED:
      return {
        ...state,
        deletedUserList: action.payload.data
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case DELETED_USER_LIST_CLEARED:
      return {
        ...state,
        userList: []
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    default:
      return state;

  }

}
