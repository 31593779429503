import React, {Component} from 'react';
import Logo from './Logo';

class LoggedOutHeader extends Component {

  render() {

    return (
      <header className="mb-3">
        <div className="text-center pt-4 pb-5">
          <Logo />
        </div>
      </header>
    )
  }
}

export default LoggedOutHeader;
