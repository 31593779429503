import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  loadUserProfileAction,
  clearUserProfileAction,
  updateUserPasswordAction,
  activateUserEmailAction
} from '../../../actions/userActions';
import ModalChangePassword from './Modals/ModalChangePassword';
import ModalUserActivateEmail from './Modals/ModalUserActivateEmail';
import LoadingIndicator from '../../ui/LoadingIndicator';

class DashboardUserProfile extends Component {

  state;

  constructor(props) {
    super(props);

    this.state = {
      currentModal: null,
      modalErrorGeneral: null,
      modalBusy: false
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.openChangePasswordModal = this.openChangePasswordModal.bind(this);
    this.openUserActivateEmailModal = this.openUserActivateEmailModal.bind(this);
    this.onSubmitPasswordChangeModal = this.onSubmitPasswordChangeModal.bind(this);
    this.onSubmitUserActivateEmailModal = this.onSubmitUserActivateEmailModal.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
  }

  componentDidMount() {
    // Dispatch an action to load the user profile from the REST API server
    this.props.dispatch(loadUserProfileAction(this.props.username));
  }

  componentWillUnmount() {
    // Dispatch an action to clear the user profile (so it doesn't appear on the screen the next time the component is loaded)
    this.props.dispatch(clearUserProfileAction());
  }

  openChangePasswordModal() {
    this.setState({ "modalErrorGeneral": null });
    this.setState({ "currentModal": "changePassword" });
  }

  openUserActivateEmailModal() {
    this.setState({ "modalErrorGeneral": null });
    this.setState({ "currentModal": "activateEmail" });
  }

  onSubmitPasswordChangeModal(event) {
    event.preventDefault();

    /**
     * Dispatch an action to update the user's password on the REST API server.
     * The action creator returns a Promise. See https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b
     */
    this.props.dispatch(updateUserPasswordAction(this.props.username, event.target.newPassword.value))
      .then(() => {
        this.setState({ "currentModal": null }); // close modal
      }, errorMessage => {
        this.setState({ "modalErrorGeneral": errorMessage });
      });
  }

  onSubmitUserActivateEmailModal(event) {
    event.preventDefault();

    /* Set modal to busy (which will disable submit button) */
    this.setState({ "modalBusy": true });

    /* Clear any existing error message */
    this.setState({ "modalErrorGeneral": null });

    const username = this.props.username;
    const password = event.target.passwordToVerify.value;

    this.props.dispatch(activateUserEmailAction(username,password))
      .then(() => {

        /* Re-enable the submit button */
        this.setState({ "modalBusy": false });

        // Close modal and reload user profile
        this.closeAllModals();
        this.props.dispatch(loadUserProfileAction(this.props.username));
      }, errorMessage => {
        /* Rewrite this error message from the API server to be more clear to user */
        errorMessage = errorMessage.replace('User does not exist or password is invalid', 'Password is incorrect');
        this.setState({ "modalErrorGeneral": errorMessage });
      });
  }

  closeAllModals() {
    this.setState({ "modalErrorGeneral": null });
    this.setState({ "currentModal": null });
  }

  render() {

    return (
      <>
        <div className="card card-dashcard">

          <div className="card-header">
            <div className="row">
              <div className="col text-start d-flex align-items-center">
                <h2>User Profile</h2>
              </div>
            </div>
          </div>

          <div className="card-body">

            {!this.props.userProfile.username && <div className="py-5"><LoadingIndicator /></div>}
            {this.props.userProfile.username &&
            <>
              <div className="row">

                <div className="col-sm-6">
                  <p className="text-start">
                    <strong>Username</strong><br />
                    { this.props.userProfile.username }
                  </p>

                  {(this.props.hasDataService || (this.props.userProfile.zohoUserId)) && <>
                    <p className="text-start">
                      <strong>Email address</strong><br />
                      {this.props.userProfile.zohoUserId == null && <>
                        <span className="font-italic text-muted">not activated</span><br />
                        {this.props.hasDataService && 
                          <button className="mt-2 btn btn-default btn-sm" onClick={this.openUserActivateEmailModal}>Activate Email</button>
                        }
                      </>}
                      {this.props.userProfile.zohoUserId !== null && <>{ this.props.userProfile.username }@myactv.net</>}
                    </p>
                  </>}

                </div>

                <div className="col-sm-6 text-center">
                  <button className="btn btn-primary" onClick={this.openChangePasswordModal}>Change Password</button>
                </div>

              </div>
              </>
            }

          </div>
        </div>
        <ModalChangePassword
          show={this.state.currentModal === "changePassword"}
          onClose={this.closeAllModals}
          onSubmit={this.onSubmitPasswordChangeModal}
          errorMessageGeneral={this.state.modalErrorGeneral} />

        <ModalUserActivateEmail
          username={this.props.userProfile.username}
          selfOrChild="self"
          show={this.state.currentModal === "activateEmail"}
          onClose={this.closeAllModals}
          onSubmit={this.onSubmitUserActivateEmailModal}
          errorMessageGeneral={this.state.modalErrorGeneral}
          modalBusy={this.state.modalBusy} />

      </>
    )

  }

}

function mapStateToProps(state) {
  return {
    authAccountNo: state.auth.accountNo,
    authToken: state.auth.authToken,
    account: state.account.account,
    userProfile: state.user.userProfile
  }
}

export default connect(mapStateToProps)(DashboardUserProfile);
