import React, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { connect } from 'react-redux';
import {
  clearDeletedUserListAction,
  loadDeletedUserListAction,
  undeleteUserAction 
  }
  from '../../../actions/deletedUserActions';
import { 
  clearActivityLogAction,
  loadActivityLogAction,
  loadUserListAction 
  } from '../../../actions/userActions';
import UserTypeBadge from '../../ui/UserTypeBadge';
import ModalUserUndelete from './Modals/ModalUserUndelete';
import ModalViewActivityLog from './Modals/ModalViewActivityLog';
import Moment from 'react-moment';
import * as AdminLevels from '../../../config/AdminLevels';

class DashboardDeletedUserList extends Component {

  state;

  constructor(props) {
    super(props);

    this.state = {
      currentModal: null,
      currentModalUserHasEmail: null,
      currentModalUsername: null,
      modalErrorGeneral: null
    };

    this.closeAllModals = this.closeAllModals.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.openUserUndeleteModal = this.openUserUndeleteModal.bind(this);
    this.openViewActivityLogModal = this.openViewActivityLogModal.bind(this);
    this.onSubmitUserUndeleteModal = this.onSubmitUserUndeleteModal.bind(this);
  }

  componentDidMount() {
    // Dispatch an action to load the user list from the REST API server
    this.props.dispatch(loadDeletedUserListAction(this.props.accountNo));
  }

  componentWillUnmount() {
    /* Dispatch an action to clear the user list (so it doesn't appear on the screen the next time the component is loaded) */
    this.props.dispatch(clearDeletedUserListAction());
  }

  closeAllModals() {
    this.setState({ "currentModal": null });
    this.setState({ "currentModalUsername": null });
    this.setState({ "currentModalUserHasEmail": null });
    this.setState({ "modalErrorGeneral": null });
    this.props.dispatch(clearActivityLogAction()); // clear contents of activity log modal
  }

  openUserUndeleteModal(event) {
    this.setState({ "currentModalUsername": event.target.dataset.username });
    this.setState({ "currentModalUserHasEmail": event.target.dataset.hasemail });
    this.setState({ "currentModal": 'userUndelete' });
  }

  /**
   * The following method dispatches an action to perform a user operation via the API server.
   * The action creator returns a Promise.
   * See https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b
   */
  onSubmitUserUndeleteModal(event) {
    event.preventDefault();

    /* Clear any existing error message */
    this.setState({ "modalErrorGeneral": null });

    const username = this.state.currentModalUsername;

    this.props.dispatch(undeleteUserAction(username))
      .then(() => {
        // Close modal and reload both user lists
        this.closeAllModals();
        this.props.dispatch(loadDeletedUserListAction(this.props.accountNo));
        this.props.dispatch(loadUserListAction(this.props.accountNo));
      }, errorMessage => {
        this.setState({ "modalErrorGeneral": errorMessage });
      });
  }

  openViewActivityLogModal(event) {

    event.preventDefault();

    const username = event.target.dataset.username;

    /* Open modal immediately */
    this.setState({ "currentModalUsername": username });
    this.setState({ "currentModal": 'viewActivityLog' });

    /* Load activity log data */
    this.props.dispatch(loadActivityLogAction(username))
      .then(() => {
        /**
         * do nothing - the activity log details will be passed directly from the props of this 
         * component to the props of the activity log modal
         */
      }, errorMessage => {
        // If an error was returned, pass it along to the modal
        this.setState({ "modalErrorGeneral": errorMessage });
      });

  }

  render() {

    var deletedUserList;

    if (this.props.deletedUserList && this.props.deletedUserList.constructor === Array && this.props.deletedUserList.length > 0) {

      deletedUserList = this.props.deletedUserList.map((user, index) =>
        <React.Fragment key={user.username}>

          <div className="row pt-1">
            <div className="col-md-6 text-start">
              <p><strong>Username</strong><br />{user.username}</p>
              <p><strong>Email</strong><br />
                {user.zohoUserId != null && <>{user.username}@myactv.net</>}
                {user.zohoUserId == null && <span className="font-italic text-muted">not activated</span>}
              </p>
            </div>
            <div className="col-md-4 text-start">
              <p><strong>User Type</strong><br /><UserTypeBadge hoh={user.hoh} /></p>
              <p><strong>Deleted At</strong><br /><Moment parse="YYYY-MM-DD HH:mm:ss Z" format="YYYY-MM-DD HH:mm">{user.deletedAt} +0000</Moment></p>
            </div>
            <div className="col-md-2 text-start">
              <p className="mb-1"><strong>Actions</strong></p>

              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic" bsPrefix="dashboardUsersActionToggle" className="btn btn-ghost btn-sm dropdown-toggle">
                  Select...
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={this.openUserUndeleteModal} data-username={user.username}>Undelete User</Dropdown.Item>
                  {this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_CSSR && <Dropdown.Item onClick={this.openViewActivityLogModal} data-username={user.username}>View Activity Log</Dropdown.Item>}
                </Dropdown.Menu>
              </Dropdown>

            </div>
          </div>

          {index < (this.props.deletedUserList.length - 1) &&
            <div className="row">
              <div className="col-12">
                <hr />
              </div>
            </div>
          }

        </React.Fragment>
      );

      return (
        <>

          <div className="card card-dashcard">

            <div className="card-header">
              <h2>Deleted Users</h2>
            </div>

            <div className="card-body">

              <p>When a user is deleted from your account, it is held for seven days.  During that time, the user can be undeleted and any email associated with that user will be restored.</p>

              <p>After seven days, the user (and any email associated with the user) will be deleted <span className="text-italic">permanently</span> and it will disappear from this list.</p>

              <div className="row">
                <div className="col-md-12">
                  <hr />
                </div>
              </div>

              {deletedUserList}

            </div>
          </div>

          <ModalUserUndelete 
            show={this.state.currentModal === "userUndelete"}
            username={this.state.currentModalUsername}
            hasEmail={this.state.currentModalUserHasEmail} 
            onClose={this.closeAllModals}
            onSubmit={this.onSubmitUserUndeleteModal}
            errorMessageGeneral={this.state.modalErrorGeneral} />

          <ModalViewActivityLog
            show={this.state.currentModal === "viewActivityLog"}
            username={this.state.currentModalUsername}
            activityLog={this.props.activityLog}
            onClose={this.closeAllModals}
            errorMessageGeneral={this.state.modalErrorGeneral} />

      </>
      )

    } else {
      // No deleted users, so return nothing
      return <></>;
    }

  }

}

function mapStateToProps(state) {
  return {
    activityLog: state.user.activityLog,
    authAccountNo: state.auth.accountNo,
    authAdminLevel: state.auth.adminLevel,
    authToken: state.auth.authToken,
    account: state.account.info,
    deletedUserList: state.deletedUser.deletedUserList,
  }
}

export default connect(mapStateToProps)(DashboardDeletedUserList);
