import React, { Component } from 'react';
import LoggedOutHeader from '../../ui/LoggedOutHeader';
import { connect } from 'react-redux';
import { gotoStep4 } from '../../../actions/forgotUsernameActions'

class ForgotUsernameStep3 extends Component {

  /* Local state for this component only */
  state;

  constructor(props) {
    super(props);

    this.state = {
      code: '',
      errorMessage: null
    }

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  onSubmit(e) {

    e.preventDefault();

    // clear any existing error messages
    this.setState({errorMessage: null})

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/account/' + Number(this.props.accountId) + '/getUsernameList';
    const postData = {
      code: this.state.code
    };
    fetch(url, {
      method: "post",
      body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then(data => {
      if (data.users) {
        this.props.dispatch(gotoStep4(data.users));
      } else {
        if (data.detail) {
          this.setState({errorMessage: data.detail})
        } else {
          this.setState({errorMessage: "An unknown error occurred"})
        }
      }

    });
  }

  render() {

    return (
      <React.Fragment>

        <LoggedOutHeader />

        <main className="container">

          <h2 className="text-center">Retrieve Username</h2>
          <h4 className="text-center">Step 3 of 4</h4>

          <form id="form-forgot-username" onSubmit={this.onSubmit}>

            <p className="text-center"></p>

              {this.props.sentVia === "email" ? (<p className="text-center">We've just sent a verification code to <strong>{ this.props.sentTo }</strong> via email. Please enter the verification code below.</p>) : (null)}
              {this.props.sentVia === "sms" ? (<p className="text-center">We've just sent a verification code to <strong>{ this.props.sentTo }</strong> via SMS text message. Please enter the verification code below.</p>) : (null)}
              {this.props.sentVia === "phone" ? (<p className="text-center">We are calling you via phone at <strong>{ this.props.sentTo }</strong> to give you a verification code. Please enter that code below.</p>) : (null)}

              <div className="form-group form-group-narrow d-block mx-auto">
                <label htmlFor="code">Enter Code</label>
                <input type="text" className="form-control" name="code" id="code" placeholder="xxxxxxxx" required maxLength="8" pattern=".{0}|.{8,8}" title="Exactly 8 characters" onChange={this.onChange} />
              </div>
              <br />

              <div className="form-group text-center">
                <button type="submit" className="btn btn-primary btn-lg btn-wide">Continue &nbsp; <i className="fa fa-caret-right" aria-hidden="true"></i></button>
              </div>

              { this.state.errorMessage !== null ? (<div className="text-center"><br /><p className="error-message"><strong>Error:</strong> { this.state.errorMessage }</p><br /></div>) : (null) }

            </form>

            {/* See https://stackoverflow.com/a/55655997/649131 for an explanation of why Link is not used here */}
            <p className="muted text-center"><small>or <a href="/">return to the login page</a></small></p>

        </main>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    accountId: state.forgotUsername.accountId,
    sentTo: state.forgotUsername.sentTo,
    sentVia: state.forgotUsername.sentVia
  }
}

export default connect(mapStateToProps)(ForgotUsernameStep3);
