/* */
import { FORGOTUSERNAME_RESET_FLOW, FORGOTUSERNAME_GOTO_STEP2, FORGOTUSERNAME_GOTO_STEP3, FORGOTUSERNAME_GOTO_STEP4 } from '../actions/types';

const initialState = {
  step: 1,
  accountId: '',
  zip: ''
}

export default function(state = initialState, action) {

  switch(action.type) {

    case FORGOTUSERNAME_RESET_FLOW:
      return {
        ...state,
        step: 1,
        accountId: '',
        zip: '',
        verificationOptions: {},
        sentTo: '',
        sentVia: '',
        users: {}
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case FORGOTUSERNAME_GOTO_STEP2:
      return {
        ...state,
        step: 2,
        accountId: action.payload.accountId,
        zip: action.payload.zip,
        verificationOptions: action.payload.verificationOptions
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case FORGOTUSERNAME_GOTO_STEP3:
      return {
        ...state,
        step: 3,
        sentTo: action.payload.sentTo,
        sentVia: action.payload.sentVia
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case FORGOTUSERNAME_GOTO_STEP4:
      return {
        ...state,
        step: 4,
        users: action.payload.users
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    default:
      return state;

  }

}
