import {
  ACCOUNT_HASDATASERVICE_CLEARED,
  ACCOUNT_HASDATASERVICE_RECEIVED,
  ACCOUNT_INFO_CLEARED,
  ACCOUNT_INFO_RECEIVED,
  ACCOUNT_MACADDRESSES_RECEIVED,
  ACCOUNT_MACADDRESSES_CLEARED,
  ACCOUNT_UPDATE_PREFERRED_EMAIL_COMPLETED
} from './types';

export const loadAccountInfoAction = (accountNo) => (dispatch, getState) => {

  const authToken = getState().auth.authToken;

  const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/account/' + Number(accountNo);

  fetch(url, {
      method: "get",
      headers: {
        'Authorization': authToken
      }
  })
  .then(res => res.json())
  .then(response => {
      dispatch(
        {
          type: ACCOUNT_INFO_RECEIVED,
          payload: {
            data: response
          }
        }
      );
  });
}

export const clearAccountInfoAction = () => {

  return {
    type: ACCOUNT_INFO_CLEARED
  };

}

export const loadMacAddressesAction = (accountNo) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/account/' + Number(accountNo) + '/getMacAddresses';

    fetch(url, {
      method: "get",
      headers: {
        'Authorization': authToken
      }
    }).then(getResponse =>  {

      if (getResponse.status === 200) {

        /* Success */
        getResponse.json().then(getResponse => {
          dispatch({
            type: ACCOUNT_MACADDRESSES_RECEIVED,
            payload: {
              data: getResponse
            }
          });
          resolve();
        });

      } else {

        /* Failure - the API server returned an error */
        getResponse.json().then(getResponse => {
          reject(getResponse.detail);
        });

      }

    }).catch( error => {
      reject('Retrieval of modem list failed due to network error. Try again later.');
    });

  });


export const clearMacAddressesAction = () => {

  return {
    type: ACCOUNT_MACADDRESSES_CLEARED
  };

}

export const updateAccountPreferredEmailAction = (accountNo, email) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

  const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/account/' + Number(accountNo) + '/updatePreferredEmail';

    const patchData = {
      "email": email
    };

    fetch(url, {
      method: "PATCH", // Note that Fetch polyfill expects PATCH verb to be uppercase
      body: JSON.stringify(patchData),
      headers: {
        'Authorization': authToken
      }
    }).then(updateResponse => {

      if (updateResponse.status === 204) {

        /* Success */
        dispatch({type: ACCOUNT_UPDATE_PREFERRED_EMAIL_COMPLETED});
        resolve();

      } else {

        /* Failure - the API server returned an error */
        updateResponse.json().then(updateResponse => {
          reject(updateResponse.detail);
        });

      }

    }).catch( error => {
      reject('Update of preferred email address failed due to network error. Try again later.');
    });

  });


export const checkForDataServiceAction = (accountNo) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/account/' + Number(accountNo) + '/getServiceCategoriesAndOccurrences';

    fetch(url, {
      method: "get",
      headers: {
        'Authorization': authToken
      }
    }).then(scoResponse =>  {

      if (scoResponse.status === 200) {
        
        scoResponse.json().then(scoResponse => {

          /* Loop over all service categories and occurrences that were returned, and extract the D for Data occurrence */
          var dataResponse = null;
          scoResponse.forEach(thisResponse => {
            if (thisResponse.categoryCode === 'D') {
              dataResponse = thisResponse.serviceOccurrences;
            }
          });

          /* If no data service occurrence was returned, resolve false now */
          if (dataResponse.length === 0) {
              dispatch({
                type: ACCOUNT_HASDATASERVICE_RECEIVED,
                payload: {
                  data: false
                }
              });
              resolve();
          }

          /* Loop over data service occurrences that were returned and assemble a list of URLs to retrieve */
          var urls = [];
          dataResponse.forEach(occurrence => {
            const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/account/' + Number(accountNo) + '/getServiceCodes/D/' + occurrence;
            urls.push(url);
          });

          /**
            * Now call these URLs and wait for all to return. See https://stackoverflow.com/questions/31710768/how-can-i-fetch-an-array-of-urls-with-promise-all
            */
          Promise.all(urls.map(url =>
             fetch(url, {
                method: "get",
                headers: {
                  'Authorization': authToken
                }
              }).then(serviceCodeResponse => {

                if (serviceCodeResponse.status === 200) {
                  return serviceCodeResponse.json();
                } else {
                  serviceCodeResponse.json().then(serviceCodeResponse => {
                    reject('Error retrieving account information');
                  });
                }

              })
            )).then(jsonResponse => {

              /* Promise.all returns an array of responses (each of which is in turn an array of service codes).
               * Merge these multiple arrays into a single flat one.  See 
               * https://stackoverflow.com/questions/10865025/merge-flatten-an-array-of-arrays
               */
              var merged = [].concat.apply([], jsonResponse);

              /* Our final result gets stored here */
              var hasDataService;

              /* If there are any elements in the resulting flat array, those are active service codes so this is a data customer */
              if (merged.length > 0) {
                hasDataService = true;
              } else {
                hasDataService = false; // already defined, but let's explicitly set it again for kicks
              }

              /* Dispatch our final result */
              dispatch({
                type: ACCOUNT_HASDATASERVICE_RECEIVED,
                payload: {
                  data: hasDataService
                }
              });
              resolve();

            }).catch(function (error) {
              /* Network-level errors get caught here */
              reject('Network error retrieving account information');
            });

        });

      } else {

        /* Failure - the API server returned an error */
        scoResponse.json().then(scoResponse => {
          reject(scoResponse.detail);
        });

      }

    }).catch( error => {
      reject('Retrieval of account information failed due to network error. Try again later.');
    });

  });

export const clearHasDataServiceAction = () => {

  return {
    type: ACCOUNT_HASDATASERVICE_CLEARED
  };

}
