import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ModalMoveUserToAnotherAccount extends Component {

  state;

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };

  }

  render() {
    return (

      <Modal show={this.props.show} onHide={this.props.onClose}>

        <form onSubmit={this.props.onSubmit}>

          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Move User to Another Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="form-group">
              <label htmlFor="newPassword">Enter New Account Number</label>
              <input type="text" className="form-control" name="newAccountNo" id="newAccountNo" placeholder="000000000" required minLength="7" maxLength="9" />
            </div>

            {this.props.errorMessageGeneral && <div className="error-message d-block mt-2">{this.props.errorMessageGeneral}</div>}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Move User
            </Button>
          </Modal.Footer>

        </form>

      </Modal>

    );
  }
}

export default ModalMoveUserToAnotherAccount;
