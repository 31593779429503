import React from "react";
import { withRouter } from "react-router-dom";

class RootUrlRedirector extends React.Component {

  componentWillMount() {
    this.props.history.push("/account/dashboard");
  }

  render() {
    return (
      <>
      </>
    );
  }

}
export default withRouter(RootUrlRedirector);
