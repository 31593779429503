import {
  USER_ACTIVITYLOG_CLEARED,
  USER_ACTIVITYLOG_RECEIVED,
  USER_LIST_RECEIVED,
  USER_LIST_CLEARED,
  USER_MOVE_ACCOUNTNO_COMPLETED,
  USER_PROFILE_RECEIVED,
  USER_PROFILE_CLEARED,
  USER_CHANGE_PASSWORD_COMPLETED,
  USER_DELETION_COMPLETED,
  USER_ADD_MODAL_USERNAME_PASSWORD_CHANGED,
  USER_CREATION_COMPLETED,
  USER_EMAIL_ACTIVATION_COMPLETED,
  USER_SET_AS_HOH_COMPLETED
  } from './types';

export const loadUserListAction = (accountNo) => (dispatch, getState) => {

  const authToken = getState().auth.authToken;

  const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/account/' + Number(accountNo) + '/user';
  fetch(url, {
      method: "get",
      headers: {
        'Authorization': authToken
      }
  })
  .then(res => res.json())
  .then(response => {
      dispatch(
        {
          type: USER_LIST_RECEIVED,
          payload: {
            data: response
          }
        }
      );
  });
}

export const loadUserProfileAction = (username) => (dispatch, getState) => {

  const authToken = getState().auth.authToken;

  const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user/' + username;
  fetch(url, {
      method: "get",
      headers: {
        'Authorization': authToken
      }
  })
  .then(res => res.json())
  .then(response => {
      dispatch(
        {
          type: USER_PROFILE_RECEIVED,
          payload: {
            data: response
          }
        }
      );
  });
}

export const loadActivityLogAction = (username) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user/' + username + '/activityLog';

    fetch(url, {
      method: "get",
      headers: {
        'Authorization': authToken
      }
    }).then(activityLogResponse => {

      if (activityLogResponse.status === 200) {

        /* Success */
        activityLogResponse.json().then(activityLogResponse => {
          dispatch({
            type: USER_ACTIVITYLOG_RECEIVED,
            payload: {
              data: activityLogResponse
            }
          });
          resolve();
        });
        resolve();

      } else {

        /* Failure - the API server returned an error */
        activityLogResponse.json().then(activityLogResponse => {
          reject(activityLogResponse.detail);
        });

      }

    }).catch( error => {
      reject('Retrieval of user activity log failed due to network error. Try again later.');
    });

  });


export const createUserAction = (username, password, accountNo, activateEmail) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user';

    const postData = {
      "username": username,
      "accountNo": accountNo,
      "hoh": false,
      "password": password,
      "status": "active",
      "activateEmail": activateEmail
    };

    fetch(url, {
      method: "post",
      body: JSON.stringify(postData),
      headers: {
        'Authorization': authToken
      }
    }).then(createResponse => {

      if (createResponse.status === 200) {

        /* Success */
        dispatch({type: USER_CREATION_COMPLETED});
        resolve();

      } else {

        /* Failure - the API server returned an error */
        createResponse.json().then(createResponse => {
          reject(createResponse.detail);
        });

      }

    }).catch( error => {
      reject('User creation failed due to network error. Try again later.');
    });

  });

export const moveUserToAnotherAccountAction = (username, newAccountNo) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user/' + username;

    /* First load user record, then change the accountNo field and submit it back as a PUT request */
    fetch(url, {
      method: "get",
      headers: {
        'Authorization': authToken
      }
    }).then(userObject => userObject.json())
    .then(userObject => {

      /* change the accountNo field */
      userObject.accountNo = newAccountNo;

      /* submit the PUT request (to the same URL but with a different HTTP method) */
      fetch(url, {
        method: "put",
        body: JSON.stringify(userObject),
        headers: {
          'Authorization': authToken
        }
      }).then(updateResponse => {

        if (updateResponse.status === 200) {

          /* Success */
          dispatch({type: USER_MOVE_ACCOUNTNO_COMPLETED});
          resolve();

        } else {

          /* Failure - the API server returned an error */
          updateResponse.json().then(updateResponse => {
            reject(updateResponse.detail);
          });

        }

      }).catch( error => {
        reject('Account number update failed due to network error. Try again later.');
      });

    }).catch( error => {
      reject('Account number update failed due to network error. Try again later.');
    });

  });

export const setUserAsHohAction = (username) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user/' + username;

    /* First load user record, then change the hoh field and submit it back as a PUT request */
    fetch(url, {
      method: "get",
      headers: {
        'Authorization': authToken
      }
    }).then(userObject => userObject.json())
    .then(userObject => {

      /* change the accountNo field */
      userObject.hoh = 1;

      /* submit the PUT request (to the same URL but with a different HTTP method) */
      fetch(url, {
        method: "put",
        body: JSON.stringify(userObject),
        headers: {
          'Authorization': authToken
        }
      }).then(updateResponse => {

        if (updateResponse.status === 200) {

          /* Success */
          dispatch({type: USER_SET_AS_HOH_COMPLETED});
          resolve();

        } else {

          /* Failure - the API server returned an error */
          updateResponse.json().then(updateResponse => {
            reject(updateResponse.detail);
          });

        }

      }).catch( error => {
        reject('Setting user as Account Owner failed due to network error. Try again later.');
      });

    }).catch( error => {
      reject('Setting user as Account Owner failed due to network error. Try again later.');
    });

  });
  
export const updateUserPasswordAction = (username, newPassword) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user/' + username;

    /* First load user record, then add the password field and submit it back as a PUT request */
    fetch(url, {
      method: "get",
      headers: {
        'Authorization': authToken
      }
    }).then(userObject => userObject.json())
    .then(userObject => {

      /* set the password field */
      userObject.password = newPassword;

      /* submit the PUT request (to the same URL but with a different HTTP method) */
      fetch(url, {
        method: "put",
        body: JSON.stringify(userObject),
        headers: {
          'Authorization': authToken
        }
      }).then(updateResponse => {

        if (updateResponse.status === 200) {

          /* Success */
          dispatch({type: USER_CHANGE_PASSWORD_COMPLETED});
          resolve();

        } else {

          /* Failure - the API server returned an error */
          updateResponse.json().then(updateResponse => {
            reject(updateResponse.detail);
          });

        }

      }).catch( error => {
        reject('Password update failed due to network error. Try again later.');
      });

    }).catch( error => {
      reject('Password update failed due to network error. Try again later.');
    });

  });
  
export const deleteUserAction = (username) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user/' + username;

    /* First load user record, then add the password field and submit it back as a PUT request */
    fetch(url, {
      method: "delete",
      headers: {
        'Authorization': authToken
      }
    }).then(deleteResponse =>  {

      if (deleteResponse.status === 204) {

        /* Success */
        dispatch({type: USER_DELETION_COMPLETED});
        resolve();

      } else {

        /* Failure - the API server returned an error */
        deleteResponse.json().then(deleteResponse => {
          reject(deleteResponse.detail);
        });

      }

    }).catch( error => {
      reject('User deletion failed due to network error. Try again later.');
    });

  });

export const activateUserEmailAction = (username, password) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user/' + username + '/activateEmail';

    const patchData = {
      "activateEmail": true,
      "password": password
    };

    fetch(url, {
      method: "PATCH",
      body: JSON.stringify(patchData),
      headers: {
        'Authorization': authToken
      }
    }).then(activateEmailResponse =>  {

      if (activateEmailResponse.status === 204) {

        /* Success */
        dispatch({type: USER_EMAIL_ACTIVATION_COMPLETED});
        resolve();

      } else {

        /* Failure - the API server returned an error */
        activateEmailResponse.json().then(activateEmailResponse => {
          reject(activateEmailResponse.detail);
        });

      }

    }).catch( error => {
      reject('User email activation failed due to network error. Try again later.');
    });

  });

export const updateUserAddModalState = (username, password) => {

  return {
    type: USER_ADD_MODAL_USERNAME_PASSWORD_CHANGED,
    payload: {
      data: {
        username: username,
        password: password
      }
    }
  };

}

export const clearActivityLogAction = () => {
  return {
    type: USER_ACTIVITYLOG_CLEARED
  };
}

export const clearUserListAction = () => {
  return {
    type: USER_LIST_CLEARED
  };
}

export const clearUserProfileAction = () => {
  return {
    type: USER_PROFILE_CLEARED
  };
}
