import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ModalUserActivateEmail extends Component {

  state;

  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };

    this.showPasswordToggleClicked = this.showPasswordToggleClicked.bind(this);
  }

  showPasswordToggleClicked() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  render() {
    return (

      <Modal show={this.props.show} onHide={this.props.onClose}>

        <form onSubmit={this.props.onSubmit}>

          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Activate Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {this.props.selfOrChild === 'self' && <>
              <p>Email service will be activated for your user account.  Your new email address will be <strong>{this.props.username}@myactv.net</strong>.</p>
              <p>To activate email service you must re-enter your password.</p>
            </>}

            {this.props.selfOrChild === 'child' && <>
              <p>Email service will be activated for the user <strong>{this.props.username}</strong>. The email address will be <strong>{this.props.username}@myactv.net</strong>.</p>
              <p>To activate email service you must enter the user's password.</p>
            </>}

            <div className="form-group">
              <label htmlFor="passwordToVerify">Enter Password for User '{this.props.username}'</label>
              <div className="input-group">
                <input type={this.state.showPassword ? 'text' : 'password'} className="form-control" name="passwordToVerify" id="passwordToVerify" aria-describedby="passwordHelpBlock" />
                <button type="button" id="showPasswordToggle" onClick={this.showPasswordToggleClicked}><i className={this.state.showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} aria-hidden="true"></i></button>
              </div>
              {this.props.errorMessagePassword && <div className="error-message d-block mt-2">{this.props.errorMessagePassword}</div>}
            </div>

            {this.props.errorMessageGeneral && <div className="error-message d-block mt-2">{this.props.errorMessageGeneral}</div>}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose} disabled={this.props.modalBusy}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={this.props.modalBusy}>
              Activate Email
            </Button>
          </Modal.Footer>

        </form>

      </Modal>

    );
  }
}

export default ModalUserActivateEmail;
