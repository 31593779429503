import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ModalUserSetAsHoh extends Component {

  render() {
    return (

      <Modal show={this.props.show} onHide={this.props.onClose}>

        <form onSubmit={this.props.onSubmit}>

          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Set user as account owner</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <p>The user <strong>{this.props.username}</strong> will be set as the Account Owner, also known as the &quot;head of household user&quot;.</p>
            <p className="text-center"><strong>Are you sure you want to do this?</strong></p>

            {this.props.errorMessageGeneral && <div className="error-message d-block mt-2">{this.props.errorMessageGeneral}</div>}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" onClick={this.props.onSubmit}>
              Set As Account Owner
            </Button>
          </Modal.Footer>

        </form>

      </Modal>

    );
  }
}

export default ModalUserSetAsHoh;
