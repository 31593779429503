import React, { Component } from 'react';
import LoggedOutHeader from '../../ui/LoggedOutHeader';

class ForgotPasswordStep5 extends Component {

  constructor(props) {
    super(props);
    this.proceedClicked = this.proceedClicked.bind(this);
  }

  proceedClicked() {
    window.location.replace('/');
  }

  render() {

    return (
      <React.Fragment>

        <LoggedOutHeader />

        <main className="container text-center">

          <h2 className="text-center">Password Reset Complete</h2>
          <br />

          <p className="text-center lead">Your password has been changed.</p>

          <p className="text-center lead">To proceed to the login screen and log in with your new password, click the button below:</p>
          <br />
          <button type="submit" onClick={this.proceedClicked} className="btn btn-primary btn-lg btn-wide">Proceed to Login Form &nbsp; <i className="fa fa-caret-right" aria-hidden="true"></i></button>

        </main>
      </React.Fragment>
    )
  }
}

export default ForgotPasswordStep5;
