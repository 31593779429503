import {
  ACCOUNT_BANDWIDTH_DATA_CLEARED,
  ACCOUNT_BANDWIDTH_DATA_RECEIVED } from './types';

export const loadBandwidthDataAction = (accountNo, macAddress) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/account/' + Number(accountNo) + '/getBandwidthUsage/' + macAddress;

    fetch(url, {
      method: "get",
      headers: {
        'Authorization': authToken
      }
    }).then(getResponse =>  {

      if (getResponse.status === 200) {

        /* Success */
        getResponse.json().then(getResponse => {
          dispatch({
            type: ACCOUNT_BANDWIDTH_DATA_RECEIVED,
            payload: {
              data: getResponse
            }
          });
          resolve();
        });

      } else {

        /* Failure - the API server returned an error */
        getResponse.json().then(getResponse => {
          reject(getResponse.detail);
        });

      }

    }).catch( error => {
      reject('Retrieval of modem list failed due to network error. Try again later.');
    });

  });

export const clearBandwidthDataAction = () => {

  return {
    type: ACCOUNT_BANDWIDTH_DATA_CLEARED
  };

}
