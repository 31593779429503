import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import LoadingIndicator from '../../../ui/LoadingIndicator';
import Moment from 'react-moment';

class ModalViewActivityLog extends Component {

  render() {

    var logContents;

    if (this.props.activityLog && this.props.activityLog.constructor === Array) {

      if (this.props.activityLog.length === 0) {

        logContents = <p>No log entries for this user</p>;

      } else {

        logContents = 
          <ul className="list-group">{
          this.props.activityLog.map((entry, index) =>
          <React.Fragment key={'activityLogEntry-' + entry.id}>

            <li className="list-group-item">
              <div className="row">
                <div className="col-md-6 text-start">
                  <small className="text-muted mb-1">
                    <Moment parse="YYYY-MM-DD HH:mm:ss Z" format="YYYY-MM-DD HH:mm">{entry.createdAt} +0000</Moment>
                    <span> by {entry.authUsername && <>{entry.authUsername}</>}{!entry.authUsername && <>[system]</>}</span>
                    <span>{entry.ipAddress && <> &middot; {entry.ipAddress}</>}</span>
                   </small>
                </div>
                <div className="col-md-6 text-center text-md-end">
                  <small className="text-muted mb-1 d-none d-md-inline" title="User's account # at the time of this entry">{entry.targetAccountNo && (entry.targetAccountNo !== -1) && <>{entry.targetAccountNo}</>}</small>
                </div>
              </div>
              <p className="m-0">{entry.description}</p>
            </li>

          </React.Fragment>
        )}
        </ul>;

      }

    } else {
      logContents = '';
    }

    return (

      <Modal show={this.props.show} onHide={this.props.onClose} size="lg">

        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Activity Log for User '{this.props.username}'</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {!this.props.activityLog && !this.props.errorMessageGeneral && <><LoadingIndicator /></>}

          {this.props.activityLog && !this.props.errorMessageGeneral && <>{logContents}</>}

          {this.props.errorMessageGeneral && <div className="error-message d-block mt-2"><strong>Error:</strong> {this.props.errorMessageGeneral}</div>}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onClose}>
            Close
          </Button>
        </Modal.Footer>

      </Modal>

    );
  }
}

export default ModalViewActivityLog;
