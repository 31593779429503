import React, {Component} from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import { withRouter } from 'react-router-dom';
import { 
  clearActivityLogAction,
  loadActivityLogAction
  } from '../../../actions/userActions';
import ModalViewActivityLog from '../Dashboard/Modals/ModalViewActivityLog';
import * as AdminLevels from '../../../config/AdminLevels';

const reactStringReplace = require('react-string-replace');

class AdminSearchResults extends Component {

  state;

  constructor(props) {
    super(props);

    this.state = {
      currentModal: null,
      currentModalUsername: null,
      modalErrorGeneral: null
    };

    this.onClickEditAccount = this.onClickEditAccount.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
    this.openViewActivityLogModal = this.openViewActivityLogModal.bind(this);
  }

  onClickEditAccount(e) {
    const accountNo = e.target.value;
    this.props.history.push('/account/' + accountNo + '/dashboard');
  }

  closeAllModals() {
    this.setState({ "currentModal": null });
    this.setState({ "currentModalUsername": null });
    this.setState({ "currentModalUserHasEmail": null });
    this.setState({ "modalErrorGeneral": null });
    this.props.dispatch(clearActivityLogAction()); // clear contents of activity log modal
  }

  /**
   * The following method dispatches an action to perform a user operation via the API server.
   * The action creator returns a Promise.
   * See https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b
   */
  openViewActivityLogModal(event) {

    event.preventDefault();

    const username = event.target.dataset.username;

    /* Open modal immediately */
    this.setState({ "currentModalUsername": username });
    this.setState({ "currentModal": 'viewActivityLog' });

    /* Load activity log data */
    this.props.dispatch(loadActivityLogAction(username))
      .then(() => {
        /**
         * do nothing - the activity log details will be passed directly from the props of this 
         * component to the props of the activity log modal
         */
      }, errorMessage => {
        // If an error was returned, pass it along to the modal
        this.setState({ "modalErrorGeneral": errorMessage });
      });

  }

  render() {

    var searchResultsHtml;

    if (this.props.searchResults && this.props.searchResults.matches && this.props.searchResults.matches.constructor === Array) {

      searchResultsHtml = this.props.searchResults.matches.map((searchResult, index) =>
        <React.Fragment key={searchResult.accountNo}>

          <div className="row pt-1">

            <div className="col-md-2 py-2">
              <div className="d-md-none label"><strong>Account: </strong></div>
              <p>{ searchResult.accountNo }</p>
            </div>

            <div className="col-md-2 py-2">
              <div className="d-md-none label"><strong>Owner: </strong></div>
              {searchResult.users.map((user, userIndex) =>
                <React.Fragment key={'owner-' + user.username}>
                  {user.hoh === 1 && <p>
                    {reactStringReplace(user.username, this.props.searchResults.searchQuery, (match, i) => (
                      <span key={i} style={{ backgroundColor: '#ccff00' }}>{match}</span>
                    ))}
                  </p>}
                </React.Fragment>
              )}
            </div>

            <div className={`py-2 pr-md-5 ${this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR ? "col-md-6" : "col-md-8"}`}>

              <div className="d-md-none label"><strong>Secondary Users: </strong></div>

              {searchResult.users.map((user, userIndex) =>
                <React.Fragment key={'secondary-' + user.username}>
                  {user.hoh === 0 && <div className="d-inline-block me-3 mb-3">

                    <span style={{
                      textDecoration: (user.status === 'deleted' ? 'line-through' : 'none'),
                      color: (user.status === 'deleted' ? '#555' : 'inherit'),
                      textStyle: (user.status === 'deleted' ? 'italic' : 'inherit')
                    }}>
                      {reactStringReplace(user.username, this.props.searchResults.searchQuery, (match, i) => (
                        <span key={i} style={{ backgroundColor: '#ccff00' }}>{match}</span>
                      ))}
                    </span>
                  </div>}
                </React.Fragment>
              )}

            </div>

            {this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR &&  
              <div className="col-md-2 py-2">
                <div>
                  <Button variant="primary" type="button" onClick={this.onClickEditAccount} value={searchResult.accountNo}>
                    Edit Account
                  </Button>
                </div>
              </div>
            }

          </div>

          {index < (this.props.searchResults.matches.length - 1) &&
            <div className="row">
              <div className="col-12 py-1">
                <hr />
              </div>
            </div>
          }

        </React.Fragment>
      );

    } else {
      searchResultsHtml = '';
    }

    var reservedUsernamesHtml;

    if (this.props.searchResults && this.props.searchResults.reservedUsernames && this.props.searchResults.reservedUsernames.constructor === Array) {

      reservedUsernamesHtml = this.props.searchResults.reservedUsernames.map((searchResult, index) =>
        <React.Fragment key={searchResult.username}>

          <div className="row pt-1">

            <div className="col-lg-2 py-2">
              <div className="d-lg-none label"><strong>Username: </strong></div>
              <p>{reactStringReplace(searchResult.username, this.props.searchResults.searchQuery, (match, i) => (
                <span key={i} style={{ backgroundColor: '#ccff00' }}>{match}</span>
              ))}</p>

            </div>

            <div className="col-lg-2 py-2">
              <div className="d-lg-none label"><strong>Source: </strong></div>
              <p>{ searchResult.source }</p>
            </div>

            <div className="col-lg-2 py-2">
              <div className="d-lg-none label"><strong>Release After: </strong></div>
              {searchResult.releaseAfter === null && <p className="text-muted">n/a</p>}
              {searchResult.releaseAfter !== null && <p>{ searchResult.releaseAfter }</p>}
            </div>

            <div className={`py-2 pr-lg-5 ${this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR ? "col-lg-4" : "col-lg-6"}`}>
              <div className="d-lg-none label"><strong>Description: </strong></div>
              {searchResult.source === 'Unmigrated User' && <p>User has not yet been migrated from the old / legacy system.</p>}
              {searchResult.source === 'Deleted User' && <p>User was permanently deleted and username is reserved to prevent re-registration for 180 days after deletion. It will be available for registration after the date indicated.</p>}
              {searchResult.source === 'Blacklist' && <p>Username is permanently reserved and is not available for registration.</p>}

            </div>

            {this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR &&
              <div className="col-lg-2 py-2 pr-lg-5">

                <div className="d-lg-none label"><strong>Actions: </strong></div>

                <Dropdown>
                  <Dropdown.Toggle id="dropdown-basic" bsPrefix="dashboardUsersActionToggle" className="btn btn-ghost btn-sm dropdown-toggle">
                    Select...
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item onClick={this.openViewActivityLogModal} data-username={searchResult.username}>View Activity Log</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

              </div>
              }

          </div>

          {index < (this.props.searchResults.reservedUsernames.length - 1) &&
            <div className="row">
              <div className="col-12 py-1">
                <hr />
              </div>
            </div>
          }

        </React.Fragment>
      );

    } else {
      reservedUsernamesHtml = '';
    }

    return (
      <>
        <main className="container py-4">

            <h1 className="pb-3">Search Results</h1>

            <div className="row">
              <div className="col-12">

                <div className="card card-dashcard">
                  <div className="card-header">
                    <h2>Matching Usernames</h2>
                  </div>
                  <div className="card-body">

                    {this.props.searchResults.matches.length > 0 &&
                      <>
                        <div className="row py-2 d-none d-md-flex">
                          <div className="col-md-2 text-start py-2"><strong>Account</strong></div>
                          <div className="col-md-2 text-start py-2"><strong>Owner</strong></div>
                          <div className={`py-2 text-start ${this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR ? "col-md-6" : "col-md-8"}`}><strong>Secondary Users</strong></div>
                          {this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR && 
                            <div className="col-md-2 text-start py-2"><strong>Actions</strong></div>
                          }
                        </div>

                        <div className="row d-none d-md-flex">
                          <div className="col-md-12">
                            <hr />
                          </div>
                        </div>
                      </>
                    }

                    {this.props.searchResults.matches.length > 0 &&
                      <>{searchResultsHtml}</>
                    }

                    {this.props.searchResults.matches.length === 0 &&
                      <p className="mt-3">No results found</p>
                    }

                  </div>
                </div>
              </div>
            </div>
            <br />

            <div className="row">
              <div className="col-12">

                <div className="card card-dashcard">
                  <div className="card-header">
                    <h2>Matching Inactive / Reserved Usernames</h2>
                  </div>
                  <div className="card-body">

                    {this.props.searchResults.reservedUsernames.length > 0 &&
                      <>
                        <div className="row py-2 d-none d-lg-flex">
                          <div className="col-lg-2 text-start py-2"><strong>Username</strong></div>
                          <div className="col-lg-2 text-start py-2"><strong>Source</strong></div>
                          <div className="col-lg-2 text-start py-2"><strong>Release After</strong></div>
                          <div className={`py-2 text-start ${this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR ? "col-lg-4" : "col-lg-6"}`}><strong>Description</strong></div>
                          {this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_TSR &&  
                            <div className="col-lg-2 text-start py-2"><strong>Actions</strong></div>
                          }
                        </div>

                        <div className="row d-none d-lg-flex">
                          <div className="col-md-12">
                            <hr />
                          </div>
                        </div>
                      </>
                    }

                    {this.props.searchResults.reservedUsernames.length > 0 &&
                      <>{reservedUsernamesHtml}</>
                    }

                    {this.props.searchResults.reservedUsernames.length === 0 &&
                      <p className="mt-3">No results found</p>
                    }

                  </div>
                </div>
              </div>
            </div>

        </main>

          <ModalViewActivityLog
            show={this.state.currentModal === "viewActivityLog"}
            username={this.state.currentModalUsername}
            activityLog={this.props.activityLog}
            onClose={this.closeAllModals}
            errorMessageGeneral={this.state.modalErrorGeneral} />

      </>
    )

  }
}

function mapStateToProps(state) {
  return {
    activityLog: state.user.activityLog,
    authAdminLevel: state.auth.adminLevel,
    searchResults: state.admin.searchResults
  }
}

export default withRouter(connect(mapStateToProps)(AdminSearchResults));
