import React, { Component } from 'react';
import LoggedOutHeader from '../../ui/LoggedOutHeader';
import { connect } from 'react-redux';
import { gotoStep3 } from '../../../actions/forgotPasswordActions'

class ForgotPasswordStep2 extends Component {

  state;

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      selectedMethod: '',
      errorMessage: null
    }

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({[e.target.name]: e.target.value})
  }

  onSubmit(e) {

    e.preventDefault();

    // clear any existing error messages
    this.setState({errorMessage: null})

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user/' + this.props.username.trim().toLowerCase() + '/sendVerificationCode/' + this.state.selectedMethod;
    const postData = {
      token: this.props.token
    };
    fetch(url, {
      method: "post",
      body: JSON.stringify(postData)
    })
    .then(res => res.json())
    .then(data => {
      if (data.result) {
        if (data.result === 'sent') {
          this.props.dispatch(gotoStep3(data.sentTo, data.sentVia));
        }
      } else {

        if (data.detail) {
          this.setState({errorMessage: data.detail})
        } else {
          this.setState({errorMessage: "An unknown error occurred"})
        }

      }

    });
  }

  render() {
    return (
      <React.Fragment>

        <LoggedOutHeader />

        <main className="container">

          <h2 className="text-center">Reset Your Password</h2>
          <h5 className="text-center">Step 2 of 4</h5>
          <br />

          <form id="form-forgot-password" onSubmit={this.onSubmit}>

              <p className="text-center">In order to proceed, we'll need to verify your identity.  Please select the method you'd like to use for verification.</p>

              <div className="form-group text-center">

                <div className="d-inline-block mx-auto text-start">

                  {this.props.methods.split(',').includes("phone") &&
                    <label className="d-flex align-items-center">
                      <input type="radio" name="selectedMethod" value="phone" required onChange={this.onChange} checked={this.state.selectedMethod === 'phone'} />
                      Call me via phone at { this.props.phone }
                    </label>
                  }

                  {this.props.methods.split(',').includes("sms") &&
                    <label className="d-flex align-items-center">
                      <input type="radio" name="selectedMethod" value="sms" required onChange={this.onChange} checked={this.state.selectedMethod === 'sms'} />
                      Send an SMS text message to { this.props.phone }
                    </label>
                  }

                  {this.props.methods.split(',').includes("email") &&
                    <label className="d-flex align-items-center">
                      <input type="radio" name="selectedMethod" value="email" required onChange={this.onChange} checked={this.state.selectedMethod === 'email'} />
                      Send an email to { this.props.email }
                    </label>
                  }

                </div>

              </div>

              { this.state.errorMessage !== null ? (<div className="text-center"><br /><p className="error-message"><strong>Error:</strong> { this.state.errorMessage }</p><br /></div>) : (null) }

              <div className="form-group text-center">
                <button type="submit" className="btn btn-primary btn-lg btn-wide">Continue &nbsp; <i className="fa fa-caret-right" aria-hidden="true"></i></button>
              </div>

            </form>

            {/* See https://stackoverflow.com/a/55655997/649131 for an explanation of why Link is not used here */}
            <p className="muted text-center"><small>or <a href="/">return to the login page</a></small></p>

        </main>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    username: state.forgotPassword.username,
    methods: state.forgotPassword.verificationOptions.methods,
    email: state.forgotPassword.verificationOptions.email,
    phone: state.forgotPassword.verificationOptions.phone,
    sms: state.forgotPassword.verificationOptions.sms,
    token: state.forgotPassword.verificationOptions.token
  }
}

export default connect(mapStateToProps)(ForgotPasswordStep2);
