/* */
import {
  DELETED_USER_LIST_RECEIVED,
  DELETED_USER_LIST_CLEARED,
  DELETED_USER_UNDELETION_COMPLETED
 } from './types';

export const loadDeletedUserListAction = (accountNo) => (dispatch, getState) => {

  const authToken = getState().auth.authToken;

  const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user/listDeletedByAccountNo/' + Number(accountNo);
  fetch(url, {
      method: "get",
      headers: {
        'Authorization': authToken
      }
  })
  .then(res => res.json())
  .then(response => {
      dispatch(
        {
          type: DELETED_USER_LIST_RECEIVED,
          payload: {
            data: response
          }
        }
      );
  });
}

export const clearDeletedUserListAction = () => {
  return {
    type: DELETED_USER_LIST_CLEARED,
  };
}

export const undeleteUserAction = (username) => (dispatch, getState) =>
  new Promise(function(resolve, reject) {

    const authToken = getState().auth.authToken;

    const url = process.env.REACT_APP_API_SERVER_URL + '/v1.0/user/' + username + '/undelete';

    fetch(url, {
      method: "PATCH",
      headers: {
        'Authorization': authToken
      }
    }).then(undeleteResponse =>  {

      if (undeleteResponse.status === 204) {

        /* Success */
        dispatch({type: DELETED_USER_UNDELETION_COMPLETED});
        resolve();

      } else {

        /* Failure - the API server returned an error */
        undeleteResponse.json().then(undeleteResponse => {
          reject(undeleteResponse.detail);
        });

      }

    }).catch( error => {
      reject('User undeletion failed due to network error. Try again later.');
    });

  });
