import React from 'react';
import { Link } from 'react-router-dom';
import LoggedOutHeader from '../ui/LoggedOutHeader';
import { BrowserRouter, Route } from 'react-router-dom';
import ForgotUsername from './Username/ForgotUsername';
import ForgotPassword from './Password/ForgotPassword';

const Forgot = () => {
  return (
    <>

      {/* Here we load the specific page based on the current URL */}
      <BrowserRouter>

          <Route exact path='/forgot/username' component={ForgotUsername} />
          <Route exact path='/forgot/password' component={ForgotPassword} />
          <Route exact path='/forgot'>
            <LoggedOutHeader />

            <main className="container text-center">

              <h2>Forget Your Username?</h2>
              <p>If you have forgotten your username, please <Link to="/forgot/username">click here</Link>.</p>

              <br />

              <h2>Forget Your Password?</h2>
              <p>If you know your username but have forgotten your password, please <Link to="/forgot/password">click here</Link>.</p>
              <br />

              {/* See https://stackoverflow.com/a/55655997/649131 for an explanation of why Link is not used here */}
              <p className="muted"><small>or you can <a href="/">return to the login page</a></small></p>
            </main>
          </Route>

      </BrowserRouter>

    </>
  )
}

export default Forgot
