import { FORGOTUSERNAME_RESET_FLOW, FORGOTUSERNAME_GOTO_STEP2, FORGOTUSERNAME_GOTO_STEP3, FORGOTUSERNAME_GOTO_STEP4 } from './types';

export const resetForgotUsernameFlow = () => {
  return {
    type: FORGOTUSERNAME_RESET_FLOW
  };
}

export const gotoStep2 = (accountId, zip, verificationOptions) => {
  return {
    type: FORGOTUSERNAME_GOTO_STEP2,
    payload: {
        accountId: accountId,
        zip: zip,
        verificationOptions: verificationOptions
    }
  };
}

export const gotoStep3 = (sentTo, sentVia) => {
  return {
    type: FORGOTUSERNAME_GOTO_STEP3,
    payload: {
        sentTo: sentTo,
        sentVia: sentVia
    }
  };
}

export const gotoStep4 = (users) => {
  return {
    type: FORGOTUSERNAME_GOTO_STEP4,
    payload: {
        users: users
    }
  };
}
