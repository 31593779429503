import React, { Component } from 'react';
import { resetForgotUsernameFlow } from '../../../actions/forgotUsernameActions'
import ForgotUsernameStep1 from './ForgotUsernameStep1';
import ForgotUsernameStep2 from './ForgotUsernameStep2';
import ForgotUsernameStep3 from './ForgotUsernameStep3';
import ForgotUsernameStep4 from './ForgotUsernameStep4';

import { connect } from 'react-redux';

class ForgotUsername extends Component {

  componentDidMount() {
    this.props.dispatch(resetForgotUsernameFlow());
  }

  render() {

    switch (this.props.step) {

      case 1:
        return <ForgotUsernameStep1 />;
        /* eslint-disable no-unreachable */
        break;
        /* eslint-enable no-unreachable */

      case 2:
        return <ForgotUsernameStep2 />;
        /* eslint-disable no-unreachable */
        break;
        /* eslint-enable no-unreachable */

      case 3:
        return <ForgotUsernameStep3 />;
        /* eslint-disable no-unreachable */
        break;
        /* eslint-enable no-unreachable */

      case 4:
        return <ForgotUsernameStep4 />;
        /* eslint-disable no-unreachable */
        break;
        /* eslint-enable no-unreachable */

      default:
        return <ForgotUsernameStep1 />;
    }

  }

}

function mapStateToProps(state) {
  return {
    step: state.forgotUsername.step
  }
}

export default connect(mapStateToProps)(ForgotUsername);
