import React from 'react';

const UserTypeBadge = (props) => {
  return (
    <>
    {props.hoh === 1 && <span className="badge badge-primary">Account Owner</span>}
    {props.hoh === 0 && <span className="badge badge-secondary">Secondary User</span>}
    </>
  )
}

export default UserTypeBadge;
