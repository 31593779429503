import {
  ACCOUNT_HASDATASERVICE_CLEARED,
  ACCOUNT_HASDATASERVICE_RECEIVED,
  ACCOUNT_INFO_CLEARED,
  ACCOUNT_INFO_RECEIVED,
  ACCOUNT_MACADDRESSES_RECEIVED,
  ACCOUNT_MACADDRESSES_CLEARED,
  ACCOUNT_UPDATE_PREFERRED_EMAIL_COMPLETED
} from '../actions/types';

const initialState = {
  hasDataService: null,  
  info: null,
  macAddresses: null
}

export default function(state = initialState, action) {

  switch(action.type) {

    case ACCOUNT_HASDATASERVICE_CLEARED:
      return {
        ...state,
        hasDataService: null
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case ACCOUNT_HASDATASERVICE_RECEIVED:

      return {
        ...state,
        hasDataService: action.payload.data
      }

      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case ACCOUNT_INFO_CLEARED:
      return {
        ...state,
        info: null
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case ACCOUNT_INFO_RECEIVED:

      return {
        ...state,
        info: action.payload.data
      }

      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case ACCOUNT_MACADDRESSES_CLEARED:
      return {
        ...state,
        macAddresses: []
      }
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case ACCOUNT_MACADDRESSES_RECEIVED:

      return {
        ...state,
        macAddresses: action.payload.data
      }

      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case ACCOUNT_UPDATE_PREFERRED_EMAIL_COMPLETED:
      return state;
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    default:
      return state;
  }

}
