import { ACCOUNT_BANDWIDTH_DATA_CLEARED, ACCOUNT_BANDWIDTH_DATA_RECEIVED } from '../actions/types';

const initialState = {}

export default function(state = initialState, action) {

  switch(action.type) {

    case ACCOUNT_BANDWIDTH_DATA_CLEARED:
      return {
        usage: [],
        max: 0
      };
      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    case ACCOUNT_BANDWIDTH_DATA_RECEIVED:

      return action.payload.data;

      /* eslint-disable no-unreachable */
      break;
      /* eslint-enable no-unreachable */

    default:
      return state;
  }

}
