import React, { Component } from 'react';
import { connect } from 'react-redux';

/*
import {
  loadUserProfileAction,
  clearUserProfileAction,
  updateUserPasswordAction,
  activateUserEmailAction
} from '../../../actions/userActions';
*/

import { loadAccountInfoAction, updateAccountPreferredEmailAction } from '../../../actions/accountActions';

import ModalChangePreferredEmail from './Modals/ModalChangePreferredEmail';
import LoadingIndicator from '../../ui/LoadingIndicator';
import * as AdminLevels from '../../../config/AdminLevels';

class DashboardPreferredEmail extends Component {

  state;

  constructor(props) {
    super(props);

    this.state = {
      currentModal: null,
      modalErrorGeneral: null,
      modalBusy: false
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentWillUnmount = this.componentWillUnmount.bind(this);
    this.openChangePreferredEmailModal = this.openChangePreferredEmailModal.bind(this);
    this.onSubmitPreferredEmailChangeModal = this.onSubmitPreferredEmailChangeModal.bind(this);
    this.closeAllModals = this.closeAllModals.bind(this);
  }

  componentDidMount() {
    // Dispatch an action to load the user profile from the REST API server
    // this.props.dispatch(loadUserProfileAction(this.props.username));
  }

  componentWillUnmount() {
    // Dispatch an action to clear the user profile (so it doesn't appear on the screen the next time the component is loaded)
    // this.props.dispatch(clearUserProfileAction());
  }

  openChangePreferredEmailModal() {
    this.setState({ "modalErrorGeneral": null });
    this.setState({ "currentModal": "changePreferredEmail" });
  }

  onSubmitPreferredEmailChangeModal(event) {

    event.preventDefault();

    /**
     * Dispatch an action to update the account's preferred email address via the REST API server.
     * The action creator returns a Promise. See https://medium.com/collaborne-engineering/returning-promises-from-redux-action-creators-3035f34fa74b
     */
    this.props.dispatch(updateAccountPreferredEmailAction(this.props.accountNo, event.target.newEmail.value))
      .then(() => {
        this.setState({ "currentModal": null }); // close modal

        // Dispatch an action to reload the account info from the REST API server
        this.props.dispatch(loadAccountInfoAction(this.props.accountNo));

      }, errorMessage => {
        this.setState({ "modalErrorGeneral": errorMessage });
      });
  }

  closeAllModals() {
    this.setState({ "modalErrorGeneral": null });
    this.setState({ "currentModal": null });
  }

  render() {

    return (
      <>
        <div className="card card-dashcard">

          <div className="card-header">
            <div className="row">
              <div className="col text-start d-flex align-items-center">
                <h2>Preferred Contact Info</h2>
              </div>
            </div>
          </div>

          <div className="card-body">

            {!this.props.accountInfo && <div className="py-5"><LoadingIndicator /></div>}
            {this.props.accountInfo &&
            <p className="text-start"><strong>Preferred Contact Email</strong><br />
              {this.props.accountInfo.preferredEmail && <>{this.props.accountInfo.preferredEmail}</>}
              {!this.props.accountInfo.preferredEmail && <span className="font-italic">(not set)</span>}
              {(this.props.hoh === '1' || this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_CSSR) &&
                <button className="plainLink ms-3 px-0" style={{textDecoration: 'underline', fontSize: '0.875em'}} onClick={this.openChangePreferredEmailModal}>Edit</button>
               }
               <br />
              <span className="form-text text-muted font-italic small">This is where we'll send your monthly invoice as well as other important account notifications.{!(this.props.hoh === '1' || this.props.authAdminLevel >= AdminLevels.ADMIN_LEVEL_CSSR) &&<> Only the Account Owner can change this.</>}</span>
            </p>
            }

          </div>
        </div>
        <ModalChangePreferredEmail
          show={this.state.currentModal === "changePreferredEmail"}
          onClose={this.closeAllModals}
          onSubmit={this.onSubmitPreferredEmailChangeModal}
          errorMessageGeneral={this.state.modalErrorGeneral} />

      </>
    )

  }

}

function mapStateToProps(state) {
  return {
    accountInfo: state.account.info,
    authAccountNo: state.auth.accountNo,
    authAdminLevel: state.auth.adminLevel,
    authToken: state.auth.authToken,
    account: state.account.account,
    hoh: state.auth.hoh,
    userProfile: state.user.userProfile
  }
}

export default connect(mapStateToProps)(DashboardPreferredEmail);
