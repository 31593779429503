import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import LoadingIndicator from '../../../ui/LoadingIndicator';
import Chart from "react-google-charts";

class ModalBandwidth extends Component {

  render() {
    return (

      <Modal show={this.props.show} onHide={this.props.onClose} size="lg">

        <Modal.Header closeButton>
          <Modal.Title className="modal-title">Bandwidth Usage{this.props.selectedMacAddress && <><br /><small><small className="text-muted">Modem MAC Address: {this.props.selectedMacAddress}</small></small></>}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {!this.props.googleChartsDataHistory && !this.props.errorMessageGeneral && <><LoadingIndicator /></>}
          {this.props.googleChartsDataHistory &&
            <>
              <h2 className="h5">Current Period</h2>
              {/* Relative position is done to remove top margin from chart. See https://github.com/rakannimer/react-google-charts/issues/86 */}
                <p>Your data usage is measured from the 1st of the month. More information is available <a target="_blank" rel="noreferrer noopener" href="http://myactv.net/tech-support/knowledge-base-article.php?article_id=146">here</a>.</p>

                <div className="row">
                  <div className="col-6 col-lg-3">
                    <p><strong>Period</strong><br />{this.props.currentPeriodDetails.period}</p>
                  </div>
                  <div className="col-6 col-lg-3">
                    <p><strong>Limit</strong><br />{this.props.currentPeriodDetails.limitTxt}</p>
                  </div>
                  <div className="col-6 col-lg-3">
                    <p><strong>Used</strong><br />{this.props.currentPeriodDetails.usedTxt}</p>
                  </div>
                  <div className="col-6 col-lg-3">
                    <p><strong>Remaining</strong><br />{this.props.currentPeriodDetails.remainingTxt}</p>
                  </div>
                </div>

              <div
                className="d-flex"
                style={{
                  position: 'relative',
                  top: '-20px'
                }}
                >
                <Chart
                  chartType="BarChart"
                  width="100%"
                  data={this.props.googleChartsDataCurrent}
                  loader={<div className="w-100 text-center"><LoadingIndicator /></div>}
                  options={{
                    isStacked: 'percent',
                    colors: ['#008fd5','#aaaaaa'],
                    backgroundColor: 'transparent',
                    legend: {
                      position: 'bottom'
                    },
                    hAxis: {
                      minValue: 0
                    },
                    enableInteractivity: false
                  }}
                  />
              </div>
              <p className="font-italic" style={{ position: 'relative', top: '-5px' }}>Actual usage shown is not real time. Last update {this.props.currentPeriodDetails.asOfDate}.</p>

              <hr />

              <h2 className="h5">Usage History</h2>
              {/* Relative position is done to remove top margin from chart. See https://github.com/rakannimer/react-google-charts/issues/86 */}
              <div
                className="d-flex"
                style={{
                  position: 'relative',
                  top: '-20px'
                }}
                >
                <Chart
                  chartType="BarChart"
                  width="100%"
                  data={this.props.googleChartsDataHistory}
                  loader={<div className="w-100 text-center"><LoadingIndicator /></div>}
                  options={{
                      colors: ['#008fd5'],
                      backgroundColor: 'transparent',
                      legend: 'none',
                      hAxis: {
                        title: 'Used Bandwidth (in GB)',
                        minValue: 0
                      },
                      enableInteractivity: false
                  }}
                  />
              </div>
            </>
          }

          {this.props.errorMessageGeneral && <div className="error-message d-block mt-2"><strong>Error:</strong> {this.props.errorMessageGeneral}</div>}

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.props.onClose}>
            Close
          </Button>
        </Modal.Footer>

      </Modal>

    );
  }
}

export default ModalBandwidth;
