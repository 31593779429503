import React, {Component} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

class ModalUserUndelete extends Component {

  render() {
    return (

      <Modal show={this.props.show} onHide={this.props.onClose}>

        <form onSubmit={this.props.onSubmit}>

          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Undelete user</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <p>The user <strong>{this.props.username}</strong> will be restored immediately.</p>

            {/* User has email */}
            {this.props.hasEmail &&
              <>
                <p>The email address <strong>{this.props.username}@myactv.net</strong> will be restored as well, with all previous mailbox contents intact.</p>
              </>
            }

            <p className="text-center"><strong>Are you sure you want to undelete user '{this.props.username}'?</strong></p>

            {this.props.errorMessageGeneral && <div className="error-message d-block mt-2">{this.props.errorMessageGeneral}</div>}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" onClick={this.props.onSubmit}>
              Undelete User
            </Button>
          </Modal.Footer>

        </form>

      </Modal>

    );
  }
}

export default ModalUserUndelete;
