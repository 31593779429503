import { combineReducers } from 'redux';
import accountReducer from './accountReducer';
import accountBandwidthReducer from './accountBandwidthReducer';
import adminReducer from './adminReducer';
import authReducer from './authReducer';
import deletedUserReducer from './deletedUserReducer';
import forgotPasswordReducer from './forgotPasswordReducer';
import forgotUsernameReducer from './forgotUsernameReducer';
import userReducer from './userReducer';

export default combineReducers({
  account: accountReducer,
  accountBandwidthUsage: accountBandwidthReducer,
  admin: adminReducer,
  auth: authReducer,
  deletedUser: deletedUserReducer,
  forgotPassword: forgotPasswordReducer,
  forgotUsername: forgotUsernameReducer,
  user: userReducer
});
