import {
  ADMIN_SEARCH_RESULTS_RECEIVED
} from '../actions/types';

const initialState = {
  searchResults: {}
}

export default function(state = initialState, action) {

  switch(action.type) {

    case ADMIN_SEARCH_RESULTS_RECEIVED:
        return {
            ...state,
            searchResults: action.payload.data
        }
        /* eslint-disable no-unreachable */
        break;
        /* eslint-enable no-unreachable */

    default:
      return state;
  }

}
